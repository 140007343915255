import { compact } from 'lodash'

import { HomepageItemBlockCtaDto, HomepageItemBlockDto } from 'types/dtos/homepage-blocks'
import {
  HomepageItemBlockModel,
  HomepageItemBlockCtaModel,
  HomepageBlockEntityModel,
  HomepageBlockItemModel,
} from 'types/models/homepage-blocks'
import { HomepageBlockEntityDto, HomepageBlockItemDto } from 'types/dtos'
import { BlockEntityType } from 'constants/home'
import { getItemThumbnail } from 'data/utils/item'
import { ItemThumbnailSize } from 'constants/images'
import { logHomeError } from 'pages/Home/utils/observability'

import { getDominantColor, transformHomepageItemDtosToProductItems } from './product-item'
import {
  transformThumbnailsBlock,
  transformItemsCardsBlock,
  transformBannersBlock,
} from './homepage-layouts'
import { transformCurrencyAmountDtoOrString } from './currency-amount'
import { transformItemPhotoDtos } from './item'
import { transformIconBadges } from './icon-badges'

export const transformHomepageItemBlockCta = (
  cta: HomepageItemBlockCtaDto | null,
): HomepageItemBlockCtaModel | null => {
  if (!cta) return null

  return {
    url: cta.url,
    title: cta.title,
    accessibilityLabel: cta.accessibility_label,
  }
}

export const transformHomepageItemBlock = (
  itemBlock: HomepageItemBlockDto,
): HomepageItemBlockModel => ({
  id: itemBlock.id,
  name: itemBlock.name,
  title: itemBlock.title,
  subtitle: itemBlock.subtitle,
  items: transformHomepageItemDtosToProductItems(itemBlock.items),
  headerCta: transformHomepageItemBlockCta(itemBlock.header_cta),
  itemCta: transformHomepageItemBlockCta(itemBlock.item_cta),
})

export const transformHomepageBlockEntity = (
  itemBlock: HomepageBlockEntityDto,
): HomepageBlockEntityModel | undefined => {
  const { type } = itemBlock

  try {
    switch (type) {
      case BlockEntityType.ItemBoxBlock:
        return { type, entity: transformHomepageItemBlock(itemBlock.entity) }
      case BlockEntityType.ThumbnailsBlock:
        return { type, entity: transformThumbnailsBlock(itemBlock.entity) }
      case BlockEntityType.ExposureBlock:
        return { type, entity: itemBlock.entity }
      case BlockEntityType.ItemsCardsBlock:
        return { type, entity: transformItemsCardsBlock(itemBlock.entity) }
      case BlockEntityType.BannersBlock:
        return { type, entity: transformBannersBlock(itemBlock.entity) }
      default:
        return undefined
    }
  } catch (err) {
    logHomeError(err, type)

    return undefined
  }
}

export const transformHomepageBlockEntities = (
  blocks: Array<HomepageBlockEntityDto>,
): Array<HomepageBlockEntityModel> => compact(blocks.map(transformHomepageBlockEntity))

export const transformHomepageBlockItemDtosToHomepageBlockItems = (
  items: Array<HomepageBlockItemDto>,
): Array<HomepageBlockItemModel> =>
  items.map(item => ({
    id: item.id,
    title: item.title,
    user: {
      id: item.user.id,
      isBusiness: item.user.business,
      login: item.user.login,
      profileUrl: item.user.profile_url,
    },
    url: item.url,
    favouriteCount: item.favourite_count,
    brandTitle: item.brand_title || '',
    sizeTitle: item.size_title || '',
    priceWithDiscount: item.discount
      ? transformCurrencyAmountDtoOrString(item.discount, item.currency)
      : null,
    price: transformCurrencyAmountDtoOrString(item.price, item.currency),
    serviceFee: item.service_fee
      ? transformCurrencyAmountDtoOrString(item.service_fee, item.currency)
      : null,
    totalItemPrice: item.total_item_price
      ? transformCurrencyAmountDtoOrString(item.total_item_price, item.currency)
      : undefined,
    badge: item.badge,
    itemClosingAction: item.item_closing_action,
    isPromoted: item.promoted,
    isFavourite: item.is_favourite,
    isHidden: item.is_hidden,
    isReserved: item.is_reserved,
    isClosed: item.is_closed,
    thumbnailUrl: item.photo && getItemThumbnail([item.photo], ItemThumbnailSize.Large),
    dominantColor: item.photo && getDominantColor([item.photo]),
    photos: (item.photo && transformItemPhotoDtos([item.photo])) || [],
    contentSource: item.content_source,
    iconBadges: transformIconBadges(item.icon_badges),
    homepageSignals: item.homepage_signals,
    status: item.status,
  }))
