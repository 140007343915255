import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getRequestState = (state: AllState) => localState(state).requestState
export const getSystemConfiguration = (state: AllState) => localState(state).systemConfiguration

export const getStripeApiKey = (state: AllState) => getSystemConfiguration(state)?.stripeApiKey

export const getPhoneNumberPrefix = (state: AllState) =>
  getSystemConfiguration(state)?.phoneNumberPrefix

export const getMeasurements = (state: AllState) => getSystemConfiguration(state)?.measurements

export const getPortal = (state: AllState) => getSystemConfiguration(state)?.portal || 'fr'

export const getBundleDiscountsConfiguration = (state: AllState) =>
  getSystemConfiguration(state)?.bundleDiscountsConfiguration

export const getBrazeConfig = (state: AllState) => getSystemConfiguration(state)?.crm.brazeConfig
