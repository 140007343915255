import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import {
  ReturnShippingOption,
  ReturnShippingMethodPayingSide,
  ReturnShippingModal,
} from 'constants/return-shipping-option'
import { ExtraServiceCheckoutModal } from 'constants/extra-service'

import {
  RequestReturnDetailsModel,
  ReturnShippingOptionModel,
  ReturnCurrencyConversionModel,
  ReturnShipmentOrderModel,
} from 'types/models'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  returnShipment: undefined,
  returnShipmentOptions: undefined,
  integratedShipmentOption: undefined,
  customShipmentOption: undefined,
  returnShipmentOrder: undefined,
  ui: {
    error: null,
    uiState: UiState.Idle,
    selectedShipmentOption: undefined,
    selectedPaymentOptionSide: undefined,
    activeModal: undefined,
    returnLabelOrder: {
      fetchUiState: UiState.Idle,
      prepareUiState: UiState.Idle,
    },
  },
}

const getReturnShipmentRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number }>
> = draft => {
  draft.ui.uiState = UiState.Pending
}

const getReturnShipmentFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.uiState = UiState.Failure
  draft.ui.error = error
}

const getReturnShipmentSuccess: CaseReducer<State, PayloadAction<RequestReturnDetailsModel>> = (
  draft,
  action,
) => {
  draft.ui.uiState = UiState.Success
  draft.ui.error = null
  draft.returnShipment = action.payload

  if (action.payload.payerSelection?.length) {
    if (
      draft.ui.selectedPaymentOptionSide === undefined ||
      draft.ui.selectedPaymentOptionSide === ReturnShippingMethodPayingSide.Seller
    ) {
      draft.ui.selectedPaymentOptionSide = ReturnShippingMethodPayingSide.Seller
    } else {
      draft.ui.selectedPaymentOptionSide = ReturnShippingMethodPayingSide.Buyer
    }
  }
}

const getReturnShippingOptionsRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number }>
> = draft => {
  draft.ui.uiState = UiState.Pending
}

const getReturnShippingOptionsFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.uiState = UiState.Failure
  draft.ui.error = error
}

const getReturnShippingOptionsSuccess: CaseReducer<
  State,
  PayloadAction<{
    returnShipment: Array<ReturnShippingOptionModel>
    currencyConversion: ReturnCurrencyConversionModel | null | undefined
  }>
> = (draft, action) => {
  const { returnShipment, currencyConversion } = action.payload

  const integratedShipmentOption = returnShipment.find(
    shipmentOption => shipmentOption.code === ReturnShippingOption.Integrated,
  )
  const customShipmentOption = returnShipment.find(
    shipmentOption => shipmentOption.code === ReturnShippingOption.Custom,
  )

  if (currencyConversion) {
    draft.currencyConversion = currencyConversion
  }

  draft.ui.uiState = UiState.Success
  draft.ui.error = null
  draft.returnShipmentOptions = returnShipment
  draft.integratedShipmentOption = integratedShipmentOption
  draft.customShipmentOption = customShipmentOption
  if (integratedShipmentOption) {
    draft.ui.selectedShipmentOption = ReturnShippingOption.Integrated
  } else if (customShipmentOption) {
    draft.ui.selectedShipmentOption = ReturnShippingOption.Custom
  }
}

const clearReturnShipmentOptions: CaseReducer<State> = draft => {
  draft.ui.uiState = UiState.Idle
  draft.ui.error = null
  draft.returnShipmentOptions = undefined
  draft.integratedShipmentOption = undefined
  draft.currencyConversion = undefined
  draft.customShipmentOption = undefined
  draft.ui.selectedShipmentOption = undefined
}

const setSelectedReturnShippingOption: CaseReducer<
  State,
  PayloadAction<{ returnShippingOption: ReturnShippingOption }>
> = (draft, action) => {
  draft.ui.selectedShipmentOption = action.payload.returnShippingOption
}

const createCustomReturnShipmentRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number; returnShippingOption: ReturnShippingOption }>
> = draft => {
  draft.ui.uiState = UiState.Pending
}

const createCustomReturnShipmentFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.uiState = UiState.Failure
  draft.ui.error = error
}

const createCustomReturnShipmentSuccess: CaseReducer<State> = draft => {
  draft.ui.uiState = UiState.Success
  draft.ui.error = null
}

const prepareReturnLabelOrderRequest: CaseReducer<
  State,
  PayloadAction<{
    transactionId: string
    pickupPointCode: string
    packageTypeId: string
    addressId: string
  }>
> = draft => {
  draft.ui.returnLabelOrder.prepareUiState = UiState.Pending
}

const prepareReturnLabelOrderFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.returnLabelOrder.prepareUiState = UiState.Failure
  draft.ui.error = error
}

const prepareReturnLabelOrderSuccess: CaseReducer<
  State,
  PayloadAction<{
    returnShipmentOrder: ReturnShipmentOrderModel
    currencyConversion?: ReturnCurrencyConversionModel | null
  }>
> = (draft, action) => {
  const { returnShipmentOrder, currencyConversion } = action.payload

  draft.ui.returnLabelOrder.prepareUiState = UiState.Success
  draft.ui.error = null
  draft.returnShipmentOrder = returnShipmentOrder

  if (currencyConversion) {
    draft.currencyConversion = currencyConversion
  }
}

const setReturnShipmentSelectedPaymentOptionSide: CaseReducer<
  State,
  PayloadAction<{ paymentOptionSide?: ReturnShippingMethodPayingSide }>
> = (draft, action) => {
  draft.ui.selectedPaymentOptionSide = action.payload.paymentOptionSide
}

const setActiveModal: CaseReducer<
  State,
  PayloadAction<{
    modal: ExtraServiceCheckoutModal | ReturnShippingModal
  }>
> = (draft, action) => {
  const { modal } = action.payload

  draft.ui.activeModal = modal
}

const cancelCheckout: CaseReducer<State> = draft => {
  draft.ui.uiState = UiState.Idle
  draft.returnShipmentOrder = undefined
}

const getReturnLabelOrderRequest: CaseReducer<
  State,
  PayloadAction<{ orderId: number }>
> = draft => {
  draft.ui.returnLabelOrder.fetchUiState = UiState.Pending
  draft.returnShipmentOrder = undefined
}

const getReturnLabelOrderFailure: CaseReducer<State, PayloadAction> = draft => {
  draft.ui.returnLabelOrder.fetchUiState = UiState.Failure
  draft.returnShipmentOrder = undefined
}

const getReturnLabelOrderSuccess: CaseReducer<
  State,
  PayloadAction<{
    returnShipmentOrder: ReturnShipmentOrderModel
  }>
> = (draft, action) => {
  draft.ui.returnLabelOrder.fetchUiState = UiState.Success
  draft.returnShipmentOrder = action.payload.returnShipmentOrder
}

const returnShipmentSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getReturnShipmentRequest,
    getReturnShipmentFailure,
    getReturnShipmentSuccess,
    getReturnShippingOptionsRequest,
    getReturnShippingOptionsFailure,
    getReturnShippingOptionsSuccess,
    setSelectedReturnShippingOption,
    createCustomReturnShipmentRequest,
    createCustomReturnShipmentFailure,
    createCustomReturnShipmentSuccess,
    clearReturnShipmentOptions,
    prepareReturnLabelOrderRequest,
    prepareReturnLabelOrderFailure,
    prepareReturnLabelOrderSuccess,
    setReturnShipmentSelectedPaymentOptionSide,
    cancelCheckout,
    setActiveModal,
    getReturnLabelOrderRequest,
    getReturnLabelOrderFailure,
    getReturnLabelOrderSuccess,
  },
})

export const { actions } = returnShipmentSlice
export const plug = { [stateName]: returnShipmentSlice.reducer }
export default returnShipmentSlice.reducer
