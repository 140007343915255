import { createAction } from '@reduxjs/toolkit'
import { Dispatch, SetStateAction } from 'react'

import { Screen } from 'constants/tracking/screens'
import { ItemDto } from 'types/dtos'

import { ItemStatus, stateName } from './constants'

export const fetchItemOfflineVerificationEligibility = createAction(
  `${stateName}/fetchItemOfflineVerificationEligibility`,
)

export const requestItemAuthenticityModal = createAction(
  `${stateName}/requestItemAuthenticityModal`,
  ({
    force = false,
    modalDataOnly = false,
  }: { force?: boolean; modalDataOnly?: boolean } = {}) => ({
    payload: {
      force,
      modalDataOnly,
    },
  }),
)

export const fetchBrands = createAction(
  `${stateName}/fetchBrands`,
  ({
    keyword = '',
    includeAllBrands = false,
  }: {
    keyword?: string
    includeAllBrands?: boolean
  }) => ({
    payload: {
      keyword,
      includeAllBrands,
    },
  }),
)

export const fetchShippingOptionsRequest = createAction(`${stateName}/fetchShippingOptionsRequest`)

export const fetchPriceSuggestions = createAction(`${stateName}/fetchPriceSuggestions`)

export const fetchItemRequest = createAction<{
  id: number | null
  tempUuid: string
  userId: number
  setItemStatus: Dispatch<SetStateAction<ItemStatus>>
}>(`${stateName}/fetchItemRequest`)

export const fetchPhotoTips = createAction(`${stateName}/fetchPhotoTips`)

type SubmitItemActionArgs = {
  tempUuid: string
  itemStatus: ItemStatus
  screenName: Screen
  saveAsDraft: boolean
  skipPackageSizeValidation: boolean
  additionalHeaders: { [key: string]: string }
  isItemPushedUp: boolean
  trackListingToGoogleTagManager: (item: ItemDto) => void
  trackListingToBraze: (item: ItemDto) => void
}

export const submitItem = createAction(
  `${stateName}/submitItem`,
  ({
    tempUuid,
    itemStatus,
    screenName,
    saveAsDraft,
    skipPackageSizeValidation,
    isItemPushedUp,
    additionalHeaders,
    trackListingToGoogleTagManager,
    trackListingToBraze,
  }: SubmitItemActionArgs) => ({
    payload: {
      tempUuid,
      itemStatus,
      screenName,
      skipPackageSizeValidation,
      additionalHeaders,
      trackListingToGoogleTagManager,
      trackListingToBraze,
    },
    meta: {
      saveAsDraft,
      isItemPushedUp,
    },
  }),
)
export const deleteDraft = createAction<{ userId: number }>(`${stateName}/deleteDraft`)

export const selectCatalog = createAction<{ catalogId: number; itemStatus: ItemStatus }>(
  `${stateName}/selectCatalog`,
)

export const fetchCatalogs = createAction(`${stateName}/fetchCatalogs`)
export const fetchStatuses = createAction(`${stateName}/fetchStatuses`)
export const fetchSizeGroupsByCatalog = createAction(`${stateName}/fetchSizeGroupsByCatalog`)
export const fetchVideoGameRatings = createAction(`${stateName}/fetchVideoGameRatings`)

export const fetchPackageSizesRequest = createAction(`${stateName}/fetchPackageSizesRequest`)

export const getDropOffLocationPrompt = createAction<{ userId: number }>(
  `${stateName}/getDropOffLocationPrompt`,
)
