export const stateName = 'itemUpload' as const
export const REQUEST_DELAY = 500
export const WITHOUT_BRAND_ID = 1 // Should match Brand::ID_EMPTY (from backend)
export const NUMBER_OF_IMAGES_PER_ITEM = 20

export enum BookDetailStatus {
  Found = 'found',
  NotFound = 'not found',
}

export enum IsbnValidity {
  Unvalidated = 'unvalidated',
  Valid = 'valid',
  Invalid = 'invalid',
}

export enum FieldName {
  Unisex = 'unisex',
  Title = 'title',
  Size = 'size_id',
  Brand = 'brand_id',
  Model = 'model',
  Status = 'status_id',
  Description = 'description',
  Price = 'price',
  PackageSize = 'package_size_id',
  DomesticShipmentPrice = 'shipment_prices.domestic',
  InternationalShipmentPrice = 'shipment_prices.international',
  Catalog = 'catalog_id',
  Color = 'color',
  Photos = 'photos',
  Isbn = 'isbn',
  Gender = 'gender_id',
  VideoGameRating = 'video_game_rating_id',
  ShippingFromLocation = 'shipping_from_location',
  ShippingSettingsNearby = 'nearby_carriers',
  ShippingSettingsDistant = 'distant_carriers',
  ShippingSettingsUnknownDistance = 'unknown_distance_carriers',
  ShippingMethod = 'shipping_method',
  Bump = 'bump',
  Manufacturer = 'manufacturer',
  ManufacturerLabel = 'manufacturer_labelling',
  MeasurementWidth = 'measurement_width',
  MeasurementLength = 'measurement_length',
}

export enum FieldTrackingName {
  Isbn = 'isbn',
  Price = 'price',
  Photo = 'photo',
  Material = 'material',
  Gender = 'gender',
  VideoGameRating = 'video_game_rating',
  Title = 'title',
  Catalog = 'catalog',
  Color = 'color',
  Description = 'description',
  Brand = 'brand',
  Size = 'size',
  Condition = 'condition',
  Category = 'category',
  CategoryUnisex = 'category_unisex',
  PackageSize = 'package_size',
  Bump = 'bump',
  MeasurementShoulderWidth = 'measurement_shoulder_width',
  MeasurementLength = 'measurement_length',
  Manufacturer = 'manufacturer_credentials',
  ManufacturerLabel = 'labeling_info',
  Model = 'model',
}

export enum ItemUploadFailReason {
  ValidationError = 'validation_error',
  ServerError = 'server_error',
  Other = 'other',
}

export enum CatalogGroupFieldCodes {
  Unisex = 'unisex',
  Gender = 'gender',
  Isbn = 'isbn',
  Author = 'author',
  BookTitle = 'book_title',
  Brand = 'brand',
  VideoGameRating = 'video_game_rating',
  Size = 'size',
  Measurements = 'measurements',
  Status = 'status',
  Color = 'color',
  Material = 'material',
  Manufacturer = 'manufacturer',
  ManufacturerLabel = 'manufacturer_labelling',
  Model = 'model',
}

export enum ItemStatus {
  New = 'upload_item',
  Edit = 'edit_item',
  DraftEdit = 'edit_draft',
}
