import { round } from 'lodash'

import { USER_THUMB_SIZE, UserThumbnailSize } from 'constants/images'
import { PhotoDto } from 'types/dtos'
import { PhotoModel } from 'types/models'

const getUserThumbnail = (photo: PhotoDto<UserThumbnailSize> | null | undefined): string | null => {
  if (!photo) return null
  if (!photo.thumbnails) return null

  const thumbnail = photo.thumbnails.find(thumb => thumb.type === USER_THUMB_SIZE)

  if (!thumbnail) return null

  return thumbnail.url
}

const getUserPhotoThumbnail = (
  photo: PhotoModel<string> | null | undefined,
  size: UserThumbnailSize = UserThumbnailSize.Default,
): string | null => {
  if (!photo) return null
  if (!photo.thumbnails) return null

  const thumbnail = photo.thumbnails.find(thumb => thumb.type === size)

  if (!thumbnail) return null

  return thumbnail.url
}

const calculateRating = (reputation: number): number => round(reputation * 5, 2)

export { getUserPhotoThumbnail, getUserThumbnail, calculateRating }
