import { ExtraServiceCheckoutPage, ExtraServiceCheckoutEntityType } from 'constants/extra-service'

import { actions as extraServiceActions } from 'state/extra-service/slice'

import { ExtraServicePaymentResp } from 'types/api/response'

import { transformPaymentAuthAction } from 'data/transformers/payment'
import { transformExtraServicePaymentDto } from 'data/transformers/extra-service-payment'
import { PageId } from 'types/page-id'

export function getExtraServiceOrderPaymentAuthAction({
  extra_service_payment,
  authentication_action,
}: ExtraServicePaymentResp) {
  const extraServicePayment = transformExtraServicePaymentDto(extra_service_payment)

  if (authentication_action) {
    const paymentAuthAction = transformPaymentAuthAction(authentication_action)

    return extraServiceActions.triggerEmbeddedPaymentAuth({
      paymentId: extraServicePayment.id,
      authAction: paymentAuthAction,
    })
  }

  if (extraServicePayment.payInRedirectUrl) {
    return extraServiceActions.triggerPaymentAuthWithRedirect({
      payInRedirectUrl: extraServicePayment.payInRedirectUrl,
    })
  }

  return null
}

export const getExtraServiceRedirectEntityType = (page: PageId | null) => {
  if (ExtraServiceCheckoutPage.Balance!.includes(page)) {
    return ExtraServiceCheckoutEntityType.DirectDonationOrder
  }
  if (ExtraServiceCheckoutPage.Donate!.includes(page)) {
    return ExtraServiceCheckoutEntityType.WebviewDirectDonationOrder
  }
  if (
    ExtraServiceCheckoutPage.MessageList!.includes(page) ||
    ExtraServiceCheckoutPage.ViewIssueDetails!.includes(page)
  ) {
    return ExtraServiceCheckoutEntityType.MsgThreadReturnLabelOrder
  }

  return ExtraServiceCheckoutEntityType.Unknown
}
