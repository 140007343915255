import { createAction } from '@reduxjs/toolkit'

import { SavedSearchApiParams } from 'types/api'

import { stateName } from './constants'

export const fetchSearchesRequest = createAction<{
  userId: number
  // only used for loading states, this is a tech debt
  searchId?: number
}>(`${stateName}/fetchSearchesRequest`)
export const fetchSearchesFailure = createAction(`${stateName}/fetchSearchesFailure`)

export const fetchSearchRequest = createAction<{
  userId: number
  searchId: number
}>(`${stateName}/fetchSearchRequest`)
export const fetchSearchFailure = createAction(`${stateName}/fetchSearchFailure`)

export const createSearchRequest = createAction<{
  userId: number
  search: SavedSearchApiParams
}>(`${stateName}/createSearchRequest`)
export const createSearchSuccess = createAction<{ searchId: number }>(
  `${stateName}/createSearchSuccess`,
)
export const createSearchFailure = createAction(`${stateName}/createSearchFailure`)

export const updateSearchRequest = createAction<{
  searchId: number
  userId: number
  search: SavedSearchApiParams
  keepLastVisitTime: boolean
}>(`${stateName}/updateSearchRequest`)

export const toggleSearchSubscription = createAction<{
  userId: number
  searchId: number
}>(`${stateName}/toggleSearchSubscription`)

export const toggleCurrentSearchSubscription = createAction<{ userId: number }>(
  `${stateName}/toggleCurrentSearchSubscription`,
)
