import {
  ClosetPromotionDto,
  ClosetPromotionOrderDto,
  ClosetPromotionMotivationDto,
  ClosetPromotionPricingDto,
} from 'types/dtos'
import {
  ClosetPromotionModel,
  ClosetPromotionMotivationModel,
  ClosetPromotionOrderModel,
  ClosetPromotionPricingModel,
} from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformClosetPromotionOrder = (
  dto: ClosetPromotionOrderDto,
): ClosetPromotionOrderModel => ({
  id: dto.id,
  total: transformCurrencyAmountDto(dto.total),
  payable: transformCurrencyAmountDto(dto.payable),
  effectiveDays: dto.effective_days,
  discount: transformCurrencyAmountDto(dto.discount),
  discountPercentage: dto.discount_percentage,
  salesTax: dto.sales_tax ? transformCurrencyAmountDto(dto.sales_tax) : null,
  isTaxCoverageAvailable: dto.tax_coverage_available ?? null,
  isTaxCovered: dto.tax_covered ?? null,
})

export const transformClosetPromotionMotivation = (
  dto: ClosetPromotionMotivationDto,
): ClosetPromotionMotivationModel => ({
  text: dto.text,
  highlight: dto.highlight,
})

export const transformClosetPromotion = (dto: ClosetPromotionDto): ClosetPromotionModel => ({
  closetPromotionOrder: transformClosetPromotionOrder(dto.closet_promotion_order),
  motivation: dto.motivation ? transformClosetPromotionMotivation(dto.motivation) : undefined,
})

export const transformClosetPromotionPricing = (
  dto: ClosetPromotionPricingDto,
): ClosetPromotionPricingModel => ({
  effectiveDays: dto.effective_days,
  isSalesTaxApplicable: dto.sales_tax_applicable,
  totalPrice: transformCurrencyAmountDto(dto.total_price),
  pricePerDay: transformCurrencyAmountDto(dto.price_per_day),
  discountedPrice: transformCurrencyAmountDto(dto.discounted_price),
  isDynamicPricing: dto.is_dynamic_pricing,
  locked: dto.locked,
  lockedNote: dto.locked_note,
})
