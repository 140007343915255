import { relativeDate } from 'libs/utils/date'

export const ACTION_CALLBACK_ROUTE_COOKIE = 'action_callback_route'
export const ANON_ID_COOKIE_EXPIRATION_DATE = relativeDate({ years: 20 }) // matches rails's `cookie.permanent`
export const ANON_ID_COOKIE_NAME = 'anon_id'
export const IN_APP_COOKIE_NAME = 'X-VINTED-IN-APP'
export const DEVICE_TOKEN_COOKIE_NAME = 'v_udt'
export const SUPPORTS_WEBP_COOKIE_NAME = 'supports_webp'
export const DEVICE_TOKEN_COOKIE_EXPIRATION_DATE = relativeDate({ years: 20 }) // matches rails's `cookie.permanent`
export const MIGRATION_CODE_COOKIE_NAME = 'migration_code'
export const USER_ID_COOKIE_NAME = 'v_uid' // won't work after we drop Rails Sessions, consider getting the `userId` from the auth
export const LOCALE_COOKIE_NAME = 'locale'

export const ANONYMOUS_LOCALE = {
  name: 'anonymous-locale',
  // mode: undefined,
  // vendor: undefined,
  maxAge: relativeDate({ years: 20 }),
  // description: 'Used for remembering unauthenticated user\'s locale',
  // category: ConsentGroup.Functional,
}
