import clientSideMetrics from 'libs/common/client-side-metrics'
import { logError } from 'libs/utils/window'

import { TabName } from '../types'

const HOME_PREFIX = 'Home'

export const logHomeError = (error: Error | null, feature?: string) => {
  if (!(error instanceof Error)) return

  const loggedFeature = feature ? `${HOME_PREFIX}_${feature}` : HOME_PREFIX

  logError(error, { feature: loggedFeature })
}

export const incrementPageLoadCounter = (state: 'initiated' | 'failure', tab: TabName) => {
  clientSideMetrics.counter(`home_page_load_${state}`, { tab }).increment()
}
