import {
  CheckoutDto,
  CheckoutExpenseItemDto,
  CheckoutPayInMethodsConfigDto,
  CheckoutPayInMethodPromotionDto,
  CheckoutPayInMethodIncentiveDto,
  CheckoutRecommendedPayInMethodDto,
} from 'types/dtos/checkout'
import {
  CheckoutModel,
  CheckoutExpenseItemModel,
  CheckoutPayInMethodsConfigModel,
  CheckoutPayInMethodPromotionModel,
  CheckoutPayInMethodIncentiveModel,
  CheckoutRecommendedPayInMethodModel,
} from 'types/models/checkout'

import { transformAddress } from './address'
import { transformCheckoutItemDtos } from './item'
import { transformCreditCardDto } from './credit-card'
import { transformPayInMethodDto } from './pay-in-method'
import { transformDeliveryTypesDto } from './delivery-types'
import { transformCurrencyConversionWithMoneyObjectDto } from './currency-conversion'
import { transformEscrowFees } from './escrow-fees'
import { transformCurrencyAmountDto, transformNullableCurrencyAmountDto } from './currency-amount'
import { transformOfflineVerificationDto } from './offline-verification'

export const transformCheckoutExpenseItemDto = ({
  price,
  discount,
  final_price,
}: CheckoutExpenseItemDto): CheckoutExpenseItemModel => ({
  price: transformCurrencyAmountDto(price),
  discount: transformCurrencyAmountDto(discount),
  finalPrice: transformCurrencyAmountDto(final_price),
})

export const transformCheckoutRecommendedPayInMethodDto = ({
  used_before,
  pay_in_method,
  external_card,
}: CheckoutRecommendedPayInMethodDto): CheckoutRecommendedPayInMethodModel => ({
  usedBefore: used_before,
  payInMethod: transformPayInMethodDto(pay_in_method),
  externalCard: external_card && transformCreditCardDto(external_card),
})

export const transformCheckoutRecommendedPayInMethodDtos = (
  payInMethods: Array<CheckoutRecommendedPayInMethodDto>,
): Array<CheckoutRecommendedPayInMethodModel> =>
  payInMethods.map(transformCheckoutRecommendedPayInMethodDto)

export const transformCheckoutPayInMethodIncentiveDto = ({
  amount,
  delayed,
  expiry_date,
}: CheckoutPayInMethodIncentiveDto): CheckoutPayInMethodIncentiveModel => ({
  isDelayed: delayed,
  expiryDate: expiry_date,
  amount: transformCurrencyAmountDto(amount),
})

export const transformCheckoutPayInMethodPromotionDto = ({
  type,
  amount,
  expiry_date,
}: CheckoutPayInMethodPromotionDto): CheckoutPayInMethodPromotionModel => ({
  type,
  expiryDate: expiry_date ?? null,
  amount: amount ? transformCurrencyAmountDto(amount) : null,
})

export const transformCheckoutPayInMethodsConfigDto = ({
  promotion,
  incentive,
  recommended,
}: CheckoutPayInMethodsConfigDto): CheckoutPayInMethodsConfigModel => ({
  promotion: promotion ? transformCheckoutPayInMethodPromotionDto(promotion) : null,
  incentive: incentive ? transformCheckoutPayInMethodIncentiveDto(incentive) : null,
  recommended: recommended ? transformCheckoutRecommendedPayInMethodDtos(recommended) : null,
})

export const transformCheckoutDto = ({
  id,
  items,
  services,
  checksum,
  payment_available,
  seller: { is_business },
  pricing: {
    base,
    payment,
    shipping,
    sales_tax,
    discount_note,
    shipping_note,
    sales_tax_covered,
    buyer_protection_fee,
    offline_verification_fee,
    conversion,
    escrow_fees,
  },
  buyer: {
    card,
    phone_number,
    pay_in_method,
    external_card_code,
    shipment_to_address,
    pay_in_methods_config,
    wallet_restricted,
    is_special_verification_required,
  },
}: CheckoutDto): CheckoutModel => ({
  id,
  checksum,
  paymentAvailable: payment_available,
  items: transformCheckoutItemDtos(items),
  seller: {
    isBusiness: is_business,
  },
  buyer: {
    phoneNumber: phone_number ?? null,
    isWalletRestricted: wallet_restricted ?? null,
    isSpecialVerificationRequired: is_special_verification_required ?? null,
    card: card ? transformCreditCardDto(card) : null,
    creditCardId: external_card_code ? `${external_card_code}` : null,
    payInMethod: pay_in_method ? transformPayInMethodDto(pay_in_method) : null,
    shipmentToAddress: shipment_to_address ? transformAddress(shipment_to_address) : null,
    payInMethodsConfig: pay_in_methods_config
      ? transformCheckoutPayInMethodsConfigDto(pay_in_methods_config)
      : null,
  },
  pricing: {
    discountNote: discount_note ?? null,
    shippingNote: shipping_note ?? null,
    base: transformCheckoutExpenseItemDto(base),
    shipping: transformCheckoutExpenseItemDto(shipping),
    buyerProtectionFee: transformCheckoutExpenseItemDto(buyer_protection_fee),
    offlineVerificationFee: offline_verification_fee
      ? transformCheckoutExpenseItemDto(offline_verification_fee)
      : null,
    isSalesTaxCovered: sales_tax_covered ?? null,
    salesTax: sales_tax ? transformNullableCurrencyAmountDto(sales_tax) : null,
    payment: {
      payIn: transformCurrencyAmountDto(payment.pay_in),
      wallet: transformCurrencyAmountDto(payment.wallet),
    },
    conversion: conversion ? transformCurrencyConversionWithMoneyObjectDto(conversion) : null,
    escrowFees: escrow_fees ? transformEscrowFees(escrow_fees) : null,
  },
  services: {
    shipping: services.shipping
      ? {
          selectedDeliveryType: services.shipping.selected_delivery_type,
          buyerPhoneRequirement: services.shipping.buyer_phone_requirement,
          deliveryTypes: transformDeliveryTypesDto(services.shipping.delivery_types),
        }
      : null,
    offlineVerification: services.offline_verification
      ? transformOfflineVerificationDto(services.offline_verification)
      : null,
  },
})
