import twitterText from 'twitter-text'
import { unescape } from 'lodash'

import { OLD_HOST_REDIRECTS, Hostname } from 'constants/hostnames'
import { LinkReplacement, LinkifiedText } from 'types/linkify'

import { filterEmptyValues, isObjectKey, isValueInObject } from './object'

const toUrlQuery = (params: object): string => {
  const keys = Object.keys(params)

  return keys
    .map(key => {
      const value = params[key]

      if (Array.isArray(value)) {
        return value.map(item => `${key}[]=${encodeURIComponent(item)}`).join('&')
      }

      return `${key}=${encodeURIComponent(value)}`
    })
    .join('&')
}

const toParams = (urlQuery: string): Record<string, string | Array<string> | undefined> => {
  const searchParams = new URLSearchParams(urlQuery)
  const params = {}

  searchParams.forEach((value, key) => {
    const potentialArrayKeyMarker = key.substring(key.length - 2, key.length)

    if (potentialArrayKeyMarker === '[]') {
      const arrayKey = key.substring(0, key.length - 2)

      if (!Array.isArray(params[arrayKey])) params[arrayKey] = []

      params[arrayKey].push(value)

      return
    }

    params[key] = value
  })

  return params
}

const toNextjsParams = (urlQuery: string): Record<string, string | Array<string> | undefined> => {
  const searchParams = new URLSearchParams(urlQuery)
  const params = {}

  searchParams.forEach((value, key) => {
    if (params[key]) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]]
      }

      params[key].push(value)
    } else {
      params[key] = value
    }
  })

  return params
}

const urlWithParams = (
  url: string,
  params: Record<
    string,
    string | number | boolean | null | undefined | Array<string | number | boolean>
  >,
): string => {
  const [, path = '/', urlParams = '', urlHash = ''] = url.match(/^([^?#]+)(\?[^#]*)?(#.*)?$/) || []

  const newParams = toUrlQuery({ ...toParams(urlParams), ...filterEmptyValues(params) })

  return `${path}${newParams ? `?${newParams}` : ''}${urlHash}`
}

const convertUrls = (
  text: string,
  replacement: LinkReplacement,
  urlRegex?: RegExp,
): LinkifiedText => {
  // @ts-ignore find a proper way to augment twiter-text module declaration
  const { extractUrl, urlHasProtocol } = twitterText.regexen
  const regex = urlRegex || extractUrl
  const doesBeginWithSpace = /^\s/
  const doesBeginWithNewline = /(\r\n|\r|\n)/
  let index = 0

  const values = {}

  const formattedText = text.replace(regex, url => {
    index += 1
    const rawUrl = url.replace(/\s+/g, '')
    const urlWithProtocol = urlHasProtocol.test(rawUrl) ? rawUrl : `//${rawUrl}`
    const paramName = `link${index}`
    let prefix = ''

    if (doesBeginWithNewline.test(url)) {
      prefix = '\n'
    } else if (doesBeginWithSpace.test(url)) {
      prefix = ' '
    }

    values[paramName] = urlRegex ? replacement(url, url) : replacement(urlWithProtocol, rawUrl)

    return `${prefix}{${paramName}}`
  })

  return {
    text: formattedText,
    values,
  }
}

const isUrlRelative = (urlToTest: string) =>
  new URL(document.baseURI).origin === new URL(urlToTest, document.baseURI).origin

const isInternalHostname = (host: string): host is Hostname => isValueInObject(host, Hostname)

const normalizeHost = (host: string) => host.replace(/^www\./, '')

const linkifyString = (text: string) => {
  const URL_OR_ANCHOR_TAG_REGEX = /(<a.*?>.*?<\/a>)|(https?:\/\/(www\.)?[a-zA-Z0-9-]+\.[^<\s]+)/gis

  const unescapedText = unescape(text)

  return unescapedText.replace(URL_OR_ANCHOR_TAG_REGEX, (match, anchor) => {
    if (anchor) return match

    return `<a href="${match}">${match}</a>`
  })
}
const normalizedQueryParam = (param: string | Array<string> | undefined) => [param].join()

const isCurrentUrl = (relativeUrl: string, pathname: string) => {
  return new RegExp(`^${pathname}(/|$)`).test(relativeUrl)
}

const getLastPathnameParam = (relativeUrl: string, url?: string) => {
  const splitRelativeUrl = (url || relativeUrl).split('/')

  return splitRelativeUrl[splitRelativeUrl.length - 1]!
}

const getPathnameParam = (relativeUrl: string, index: number) => {
  const splitRelativeUrl = relativeUrl.split('/')

  // splitRelativeUrl[0] is an empty string, hence returning index + 1
  // returning empty string for consistency sake when url has no params, e.g. vinted.com/
  return splitRelativeUrl[index + 1] || ''
}

const getOldHostRedirectUrl = (host: string) => {
  const normalizedHost = normalizeHost(host)

  if (!isObjectKey(normalizedHost, OLD_HOST_REDIRECTS)) return ''

  return `https://${OLD_HOST_REDIRECTS[normalizedHost]}`
}

const replaceUrlHost = (url: string, host: string) => {
  if (!host.match(/^https?:\/\//)) return url

  return url.replace(/^(https?:\/\/[^/]+\/|\/)/, `${host}/`)
}

const removeParamsFromQuery = (
  relativeUrl: string,
  urlQuery: string,
  paramsToRemove: Array<string>,
) => {
  const urlParams = toParams(urlQuery)

  paramsToRemove.forEach(param => delete urlParams[param])

  if (!Object.keys(urlParams).length) return relativeUrl

  return `${relativeUrl}?${toUrlQuery(urlParams)}`
}

const getReferrerPath = () => (document.referrer ? new URL(document.referrer).pathname : 'unknown')

/**
 * Changes the origin of the url to the current window origin.
 */
function mapOriginToWindow(url: string) {
  try {
    const { pathname, searchParams } = new URL(url)

    const localUrl = new URL(pathname, window.location.origin)

    searchParams.forEach((value, key) => {
      localUrl.searchParams.set(key, value)
    })

    return localUrl.toString()
  } catch {
    return url
  }
}

function isInternalUrl(url: string) {
  if (isUrlRelative(url)) return true

  const { hostname } = new URL(url)

  return isInternalHostname(normalizeHost(hostname))
}

export {
  mapOriginToWindow,
  toUrlQuery,
  toParams,
  toNextjsParams,
  urlWithParams,
  convertUrls,
  linkifyString,
  isUrlRelative,
  isInternalHostname,
  normalizedQueryParam,
  isCurrentUrl,
  getLastPathnameParam,
  getPathnameParam,
  normalizeHost,
  getOldHostRedirectUrl,
  replaceUrlHost,
  removeParamsFromQuery,
  getReferrerPath,
  isInternalUrl,
}
