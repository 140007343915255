import uuid from 'uuid'
import { noop } from 'lodash'
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SavedSearchDto } from 'types/dtos'

import { SelectedSection, stateName } from './constants'
import { State } from './types'

export const initialState: State = {
  searches: {
    listId: uuid.v4(),
    byId: {},
    ids: [],
  },
  subscribedSearches: {
    listId: uuid.v4(),
    ids: [],
  },
  selectedSection: SelectedSection.Recent,
  currentSearchId: null,
  isSubscribeModalOpen: false,
  isSearchLoadingMap: {},
  isCatalogSearchButtonLoading: false,
}

const genSavedRecentSearchListIds: CaseReducer<State> = draft => {
  draft.searches.listId = uuid.v4()
  draft.subscribedSearches.listId = uuid.v4()
}

const setSelectedSection: CaseReducer<State, PayloadAction<SelectedSection>> = (draft, action) => {
  draft.selectedSection = action.payload
}

const fetchSubscribedSearchesRequest: CaseReducer<State, PayloadAction<{ userId: number }>> = noop

const fetchSubscribedSearchesFailure: CaseReducer<State> = noop

const fetchSubscribedSearchesSuccess: CaseReducer<
  State,
  PayloadAction<{ searches: Array<SavedSearchDto> }>
> = (draft, action) => {
  const { searches } = action.payload

  searches.forEach(search => {
    draft.searches.byId[search.id] = search
  })

  draft.subscribedSearches.ids = searches.map(search => search.id)
}

const setIsCatalogSearchButtonLoading: CaseReducer<State, PayloadAction<boolean>> = (
  draft,
  action,
) => {
  draft.isCatalogSearchButtonLoading = action.payload
}

const setIsSearchLoadingMap: CaseReducer<State, PayloadAction<{ id: number; value: boolean }>> = (
  draft,
  action,
) => {
  const { id, value } = action.payload
  draft.isSearchLoadingMap[id] = value
}

const fetchSearchesSuccess: CaseReducer<
  State,
  PayloadAction<{ searches: Array<SavedSearchDto> }>
> = (draft, action) => {
  const { searches } = action.payload

  searches.forEach(search => {
    draft.searches.byId[search.id] = search
  })

  draft.searches.ids = searches.map(search => search.id)
}

const fetchSearchSuccess: CaseReducer<State, PayloadAction<{ search: SavedSearchDto }>> = (
  draft,
  action,
) => {
  const { search } = action.payload

  draft.searches.byId[search.id] = search
}

const openSubscribeModal: CaseReducer<State> = draft => {
  draft.isSubscribeModalOpen = true
}

const closeSubscribeModal: CaseReducer<State> = draft => {
  draft.isSubscribeModalOpen = false
}

const setCurrentSearch: CaseReducer<State, PayloadAction<{ currentSearchId: number }>> = (
  draft,
  action,
) => {
  draft.currentSearchId = action.payload.currentSearchId
}

const setSearchSubscribed: CaseReducer<
  State,
  PayloadAction<{ subscribed: boolean; searchId: number }>
> = (draft, action) => {
  const { searchId, subscribed } = action.payload

  const search = draft.searches.byId[searchId]
  if (!search) return

  search.subscribed = subscribed
}

const savedSearchesSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setSearchSubscribed,
    setSelectedSection,
    fetchSubscribedSearchesFailure,
    fetchSubscribedSearchesSuccess,
    fetchSubscribedSearchesRequest,
    setIsSearchLoadingMap,
    setIsCatalogSearchButtonLoading,
    fetchSearchesSuccess,
    fetchSearchSuccess,
    openSubscribeModal,
    closeSubscribeModal,
    setCurrentSearch,
    genSavedRecentSearchListIds,
    setSearchId: setCurrentSearch,
  },
})

export const { actions } = savedSearchesSlice
export const plug = { [stateName]: savedSearchesSlice.reducer }
export default savedSearchesSlice.reducer
