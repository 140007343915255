import {
  UPLOADED_ITEM_ID,
  UPLOADED_ITEM_PROMOTIONS,
  PromotionStorageKeys,
  ItemAfterUploadActions,
} from 'constants/item-upload'
import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'
import { abTestExposeEvent } from 'libs/common/event-tracker/events'
import { DraftItemResp, ItemCompletionResp, ItemResp, ResponseError } from 'types/api'
import { ItemUploadABTestModel } from 'types/models'

export function setItemIdToStorage(itemId: number) {
  setLocalStorageItem(UPLOADED_ITEM_ID, itemId.toString())
}

export function setPromotionsAfterItemUploadToStorage(
  response: DraftItemResp | ItemResp | ItemCompletionResp | ResponseError,
  promotion: PromotionStorageKeys,
) {
  if ('errors' in response) return
  if (response.after_upload_actions?.includes(ItemAfterUploadActions.ShowOfflineVerificationModal))
    return
  if (!('item' in response)) return
  if (!response.item.id) return

  const uploadedItemPromotions = getLocalStorageItem(UPLOADED_ITEM_PROMOTIONS)
  const newUploadedItemPromotions = JSON.parse(uploadedItemPromotions || '[]')

  if (response.after_upload_actions?.includes(ItemAfterUploadActions.ShowUploadAnotherItemTip)) {
    newUploadedItemPromotions.push(PromotionStorageKeys.ShowUploadAnotherItemTip)
  } else {
    newUploadedItemPromotions.push(promotion)
  }

  setLocalStorageItem(UPLOADED_ITEM_PROMOTIONS, JSON.stringify(newUploadedItemPromotions))
}

export const itemUploadABTestExposeEvent = (abTest: ItemUploadABTestModel) => {
  return abTestExposeEvent({
    id: abTest.testId,
    name: abTest.testName,
    variant: abTest.variant || undefined,
    anonId: abTest.testAnonId,
    userId: abTest.testUserId,
    countryCode: abTest.countryCode,
  })
}
