import { combineReducers, createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { initialEntityStructure } from 'libs/utils/redux'
import { UiState as UiStateEnum } from 'constants/ui'
import { ResponseError } from 'types/api'
import { FeedbackListModel, FeedbackModel } from 'types/models'
import { FeedbackFilterType } from 'pages/Profile/types'

import { stateName, FeedbackScreen } from './constants'
import { DataState, UiState } from './types'

export const initialDataState: DataState = {
  ...initialEntityStructure,
  byTranslatedId: {},
}

export const initialUiState: UiState = {
  uiState: UiStateEnum.Idle,
  feedbackState: {},
  currentPage: 0,
  totalPages: 0,
}

const feedbackGetSuccess: CaseReducer<
  DataState,
  PayloadAction<{ feedbackData: FeedbackListModel }>
> = (draft, action) => {
  const { feedback } = action.payload.feedbackData

  feedback.forEach(item => {
    draft.byId[item.id] = item
    draft.ids.push(item.id)
  })
}

const feedbackDeleteSuccess: CaseReducer<DataState, PayloadAction<{ feedbackId: number }>> = (
  draft,
  action,
) => {
  const { feedbackId } = action.payload

  draft.ids = draft.ids.filter(itemId => itemId !== feedbackId)
  delete draft.byId[feedbackId]
}

const updateFeedback: CaseReducer<
  DataState,
  PayloadAction<{ feedbackId: number; feedback: FeedbackModel }>
> = (draft, action) => {
  const { feedback } = action.payload

  draft.byId[feedback.id] = feedback
  delete draft.byTranslatedId[feedback.id]
}

const feedbackTranslateSuccess: CaseReducer<
  DataState,
  PayloadAction<{ feedbackId: number; feedback: FeedbackModel; localize: boolean }>
> = (draft, action) => {
  const { feedback } = action.payload

  draft.byTranslatedId[feedback.id] = feedback
}

const feedbackClearIds: CaseReducer<DataState> = draft => {
  draft.ids = []
}

const dataSlice = createSlice({
  name: stateName,
  initialState: initialDataState,
  reducers: {
    feedbackGetSuccess,
    feedbackDeleteSuccess,
    feedbackTranslateSuccess,
    feedbackCommentCreateSuccess: updateFeedback,
    feedbackCommentEditSuccess: updateFeedback,
    feedbackCommentDeleteSuccess: updateFeedback,
    feedbackClearIds,
  },
})

const setFeedbackUiStateById = (draft: UiState, feedbackId: number, uiState: UiStateEnum) => {
  draft.feedbackState[feedbackId]!.uiState = uiState
}

const setFeedbackScreenById = (draft: UiState, feedbackId: number, screen: FeedbackScreen) => {
  draft.feedbackState[feedbackId]!.screen = screen
}

const setFeedbackUiState =
  <P extends { feedbackId: number }>(
    uiState: UiStateEnum,
  ): CaseReducer<UiState, PayloadAction<P>> =>
  (draft, action) => {
    const { feedbackId } = action.payload

    setFeedbackUiStateById(draft, feedbackId, uiState)
  }

const setFeedbackScreen =
  (screen: FeedbackScreen): CaseReducer<UiState, PayloadAction<{ feedbackId: number }>> =>
  (draft, action) => {
    const { feedbackId } = action.payload

    setFeedbackScreenById(draft, feedbackId, screen)
  }

const feedbackGetRequest: CaseReducer<
  UiState,
  PayloadAction<{ userId: number; feedbackFilterType?: FeedbackFilterType }>
> = draft => {
  draft.uiState = UiStateEnum.Pending
}

const feedbackGetFailure: CaseReducer<UiState> = draft => {
  draft.uiState = UiStateEnum.Failure
}

const feedbackGetSuccessUi: CaseReducer<
  UiState,
  PayloadAction<{ feedbackData: FeedbackListModel }>
> = (draft, action) => {
  const { feedback, pagination } = action.payload.feedbackData
  const { totalPages, currentPage } = pagination
  draft.totalPages = totalPages
  draft.currentPage = currentPage
  draft.uiState = UiStateEnum.Success

  if (draft.showFilters === undefined) {
    draft.showFilters = feedback.length > 0
  }

  feedback.forEach(item => {
    draft.feedbackState[item.id] = {
      uiState: UiStateEnum.Idle,
      screen: FeedbackScreen.View,
      error: null,
      isTranslated: false,
    }
  })
}

const feedbackDeleteSuccessUiState: CaseReducer<
  UiState,
  PayloadAction<{ feedbackId: number; feedback: FeedbackModel; localize: boolean }>
> = (draft, action) => {
  const { feedbackId } = action.payload

  delete draft.feedbackState[feedbackId]
}

const feedbackToggleTranslated: CaseReducer<
  UiState,
  PayloadAction<{ feedbackId: number; localize: boolean }>
> = (draft, action) => {
  const { feedbackId, localize } = action.payload

  draft.feedbackState[feedbackId]!.isTranslated = localize
}

const feedbackActionSuccess: CaseReducer<
  UiState,
  PayloadAction<{ feedbackId: number; feedback: FeedbackModel }>
> = (draft, action) => {
  const { feedbackId } = action.payload

  draft.feedbackState[feedbackId]!.isTranslated = false
  setFeedbackUiStateById(draft, feedbackId, UiStateEnum.Success)
  setFeedbackScreenById(draft, feedbackId, FeedbackScreen.View)
}

const feedbackActionFailure: CaseReducer<
  UiState,
  PayloadAction<{ feedbackId: number; error: ResponseError | null }>
> = (draft, action) => {
  const { feedbackId, error } = action.payload

  setFeedbackUiStateById(draft, feedbackId, UiStateEnum.Failure)
  draft.feedbackState[feedbackId]!.error = error
}

const feedbackTranslateSuccessUi: CaseReducer<
  UiState,
  PayloadAction<{ feedbackId: number; localize: boolean }>
> = (draft, action) => {
  const { feedbackId, localize } = action.payload

  setFeedbackUiStateById(draft, feedbackId, UiStateEnum.Success)
  draft.feedbackState[feedbackId]!.isTranslated = localize
}

const feedbackToggleCancel: CaseReducer<UiState, PayloadAction<{ feedbackId: number }>> = (
  draft,
  action,
) => {
  const { feedbackId } = action.payload

  setFeedbackUiStateById(draft, feedbackId, UiStateEnum.Idle)
  setFeedbackScreenById(draft, feedbackId, FeedbackScreen.View)
  draft.feedbackState[feedbackId]!.error = null
}

const feedbackResetCurrentPage: CaseReducer<UiState> = draft => {
  draft.currentPage = 0
}

const uiSlice = createSlice({
  name: stateName,
  initialState: initialUiState,
  reducers: {
    feedbackGetRequest,
    feedbackGetFailure,
    feedbackDeleteRequest: setFeedbackUiState<{ feedbackId: number }>(UiStateEnum.Pending),
    feedbackDeleteFailure: feedbackActionFailure,
    feedbackTranslateRequest: setFeedbackUiState<{ feedbackId: number; localize: boolean }>(
      UiStateEnum.Pending,
    ),
    feedbackTranslateFailure: feedbackActionFailure,
    feedbackCommentCreateRequest: setFeedbackUiState<{ feedbackId: number; comment: string }>(
      UiStateEnum.Pending,
    ),
    feedbackCommentCreateFailure: feedbackActionFailure,
    feedbackCommentEditRequest: setFeedbackUiState<{ feedbackId: number; comment: string }>(
      UiStateEnum.Pending,
    ),
    feedbackCommentEditFailure: feedbackActionFailure,
    feedbackCommentDeleteRequest: setFeedbackUiState<{ feedbackId: number }>(UiStateEnum.Pending),
    feedbackCommentDeleteFailure: feedbackActionFailure,
    feedbackToggleReply: setFeedbackScreen(FeedbackScreen.CreateComment),
    feedbackToggleCommentEdit: setFeedbackScreen(FeedbackScreen.EditComment),
    feedbackToggleDelete: setFeedbackScreen(FeedbackScreen.DeleteFeedback),
    feedbackToggleCommentDelete: setFeedbackScreen(FeedbackScreen.DeleteComment),
    feedbackToggleCancel,
    feedbackToggleTranslated,
    feedbackResetCurrentPage,
  },
  extraReducers: {
    [dataSlice.actions.feedbackGetSuccess.type]: feedbackGetSuccessUi,
    [dataSlice.actions.feedbackDeleteSuccess.type]: feedbackDeleteSuccessUiState,
    [dataSlice.actions.feedbackTranslateSuccess.type]: feedbackTranslateSuccessUi,
    [dataSlice.actions.feedbackCommentCreateSuccess.type]: feedbackActionSuccess,
    [dataSlice.actions.feedbackCommentEditSuccess.type]: feedbackActionSuccess,
    [dataSlice.actions.feedbackCommentDeleteSuccess.type]: feedbackActionSuccess,
  },
})

export const dataReducer = dataSlice.reducer
export const uiReducer = uiSlice.reducer
const userFeedbackReducer = combineReducers({
  ui: uiReducer,
  data: dataReducer,
})

export const actions = {
  ...dataSlice.actions,
  ...uiSlice.actions,
}
export const plug = { [stateName]: userFeedbackReducer }
export default userFeedbackReducer
