import { takeEvery, takeLatest, put, fork, race, delay } from 'redux-saga/effects'
import { select, call, take } from 'typed-redux-saga'
import { PayloadAction } from '@reduxjs/toolkit'

import * as api from 'data/api'

import * as sessionSelectors from 'state/session/selectors'
import * as catalogFilterSelectors from 'state/catalog-filters/selectors'
import * as catalogFilterSagas from 'state/catalog-filters/sagas'
import { actions as catalogFilterActions } from 'state/catalog-filters/slice'
import { SavedSearchDto } from 'types/dtos'

import { saveFirstTimeSubscribe, isSavedSearchUser } from './utils'
import { filtersToApiParams, searchDtoToApiParams } from './transformers'
import * as statelessActions from './actions'
import { actions } from './slice'
import * as selectors from './selectors'
import { SEARCH_CHECK_DEBOUNCE_AMOUNT } from './constants'

export function* fetchSubscribedSearches({
  payload,
}: ReturnType<typeof actions.fetchSubscribedSearchesRequest>) {
  const { userId } = payload

  const response = yield* call(api.getSubscribedSearches, userId)

  if ('errors' in response) {
    yield put(actions.fetchSubscribedSearchesFailure())

    return
  }

  yield put(actions.fetchSubscribedSearchesSuccess({ searches: response.searches }))
}

export function* fetchSearches({
  payload,
}: ReturnType<typeof statelessActions.fetchSearchesRequest>) {
  const { userId, searchId } = payload

  const response = yield* call(api.getSortedSavedSearches, userId)

  if (searchId) {
    yield put(actions.setIsSearchLoadingMap({ id: searchId, value: false }))
  }
  yield put(actions.setIsCatalogSearchButtonLoading(false))

  if ('errors' in response) {
    yield put(statelessActions.fetchSearchesFailure())

    return
  }

  yield put(
    actions.fetchSearchesSuccess({
      searches: response.searches,
    }),
  )
}

export function* fetchSearch({ payload }: ReturnType<typeof statelessActions.fetchSearchRequest>) {
  const { userId, searchId } = payload

  const response = yield* call(api.getSavedSearch, { id: searchId, userId })

  if ('errors' in response) {
    yield put(statelessActions.fetchSearchFailure())

    return
  }

  yield put(actions.fetchSearchSuccess({ search: response.search }))
}

// Since the API does not return a full search object on create or update requests, we must send
// an additional request to get the full data and put it in the store.
export function* refreshSearchesAfterSave({
  userId,
  searchId,
}: {
  userId: number
  searchId?: number
}) {
  yield take([statelessActions.createSearchSuccess])

  yield put(statelessActions.fetchSearchesRequest({ userId, searchId }))
}

export function* setCurrentSearch() {
  const winner = yield race({
    success: take(statelessActions.createSearchSuccess),
    failure: take(statelessActions.createSearchFailure),
  })

  if (!winner.success) return

  yield put(actions.setCurrentSearch({ currentSearchId: winner.success.payload.searchId }))

  yield* call(catalogFilterSagas.updateUrl, { shouldReplaceHistory: true })
}

export function* createSearch({
  payload,
}: ReturnType<typeof statelessActions.createSearchRequest>) {
  const { userId, search } = payload

  yield fork(setCurrentSearch)

  const response = yield* call(api.createSavedSearch, { userId, search })

  if ('errors' in response) {
    yield put(statelessActions.createSearchFailure())

    return
  }

  yield fork(refreshSearchesAfterSave, { userId })
  yield put(statelessActions.createSearchSuccess({ searchId: response.search.id }))
}

export function* updateSearch({
  payload,
}: ReturnType<typeof statelessActions.updateSearchRequest>) {
  const { searchId, userId, search, keepLastVisitTime } = payload

  const params = {
    userId,
    search,
    keepLastVisitTime,
  }

  const response = yield* call(api.updateSavedSearch, { id: searchId, ...params })

  yield put(actions.setIsSearchLoadingMap({ id: searchId, value: false }))
  yield put(actions.setIsCatalogSearchButtonLoading(false))

  if ('errors' in response) {
    return
  }

  yield put(actions.setSearchSubscribed({ searchId, subscribed: response.search.subscribed }))
}

export function* showSearchSubscriptionEducation() {
  const userHasUsedSavedSearches = yield* call(isSavedSearchUser)

  if (!userHasUsedSavedSearches) {
    yield call(saveFirstTimeSubscribe)
    yield put(actions.openSubscribeModal())
  }
}

export function* toggleSearchSubscriptionFlow({
  payload,
}: ReturnType<typeof statelessActions.toggleSearchSubscription>) {
  const { userId, searchId } = payload
  const search = yield* select(selectors.getSearchById, searchId)

  if (!search) return

  if (!search.subscribed) {
    yield fork(showSearchSubscriptionEducation)
  }

  yield put(actions.setIsSearchLoadingMap({ id: searchId, value: true }))

  const searchParams = { ...searchDtoToApiParams(search), subscribed: !search.subscribed }

  yield put(
    statelessActions.updateSearchRequest({
      searchId,
      userId,
      search: searchParams,
      keepLastVisitTime: true,
    }),
  )

  yield put(actions.genSavedRecentSearchListIds())
}

export function* toggleCurrentSearchSubscriptionFlow({
  payload,
}: ReturnType<typeof statelessActions.toggleCurrentSearchSubscription>) {
  const { userId } = payload
  let search = yield* select(selectors.getCurrentSearch)
  const currentSearchId = yield* select(selectors.getCurrentSearchId)

  if (currentSearchId) {
    yield put(statelessActions.fetchSearchRequest({ userId, searchId: currentSearchId }))

    const action = yield* take<PayloadAction<{ search: SavedSearchDto }>>(
      actions.fetchSearchSuccess,
    )

    search = action.payload.search
  }

  if (!search?.subscribed) {
    yield fork(showSearchSubscriptionEducation)
  }

  yield put(actions.setIsCatalogSearchButtonLoading(true))

  if (search) {
    const searchParams = { ...searchDtoToApiParams(search), subscribed: !search.subscribed }

    yield put(
      statelessActions.updateSearchRequest({
        searchId: search.id,
        userId,
        search: searchParams,
        keepLastVisitTime: true,
      }),
    )
  } else {
    const filters = yield* select(catalogFilterSelectors.getFilters)
    const selectedDynamicFilters = yield* select(catalogFilterSelectors.getSelectedDynamicFilters)
    const searchParams = {
      ...filtersToApiParams(filters, selectedDynamicFilters),
      subscribed: true,
    }

    yield put(statelessActions.createSearchRequest({ userId, search: searchParams }))
  }
}

export function* unifiedCurrentSearchFlow({ forceUpdate }: { forceUpdate?: boolean }) {
  yield delay(SEARCH_CHECK_DEBOUNCE_AMOUNT)

  const userId = yield* select(sessionSelectors.getUserId)
  const filters = yield* select(catalogFilterSelectors.getFilters)
  const selectedDynamicFilters = yield* select(catalogFilterSelectors.getSelectedDynamicFilters)
  const isPopularCatalog = yield* select(catalogFilterSelectors.getIsPopularInCatalog)
  const searchParams = filtersToApiParams(filters, selectedDynamicFilters)
  const currentSearchId = yield* select(selectors.getCurrentSearchId)

  let currentSearch = yield* select(selectors.getCurrentSearch)

  if (!userId || isPopularCatalog) return

  if (currentSearchId && !currentSearch) {
    yield put(statelessActions.fetchSearchRequest({ userId, searchId: currentSearchId }))

    const action = yield* take<PayloadAction<{ search: SavedSearchDto }>>(
      actions.fetchSearchSuccess,
    )

    currentSearch = action.payload.search
  }

  const isQuerySame = (filters.query || '') === (currentSearch?.search_text || '')
  const shouldCreateRecent = !currentSearch || !isQuerySame || currentSearch.subscribed

  if (shouldCreateRecent && !forceUpdate) {
    yield put(
      statelessActions.createSearchRequest({
        userId,
        search: { ...searchParams, subscribed: false },
      }),
    )

    return
  }

  if (currentSearch) {
    yield put(
      statelessActions.updateSearchRequest({
        userId,
        searchId: currentSearch.id,
        search: { ...searchParams, subscribed: currentSearch.subscribed },
        keepLastVisitTime: false,
      }),
    )
  }
}

export default function* saga() {
  yield takeEvery(statelessActions.fetchSearchesRequest, fetchSearches)
  yield takeEvery(statelessActions.fetchSearchRequest, fetchSearch)
  yield takeEvery(statelessActions.createSearchRequest, createSearch)
  yield takeEvery(statelessActions.updateSearchRequest, updateSearch)
  yield takeEvery(actions.fetchSubscribedSearchesRequest, fetchSubscribedSearches)

  // User action invoked flows
  yield takeEvery(statelessActions.toggleSearchSubscription, toggleSearchSubscriptionFlow)
  yield takeEvery(
    statelessActions.toggleCurrentSearchSubscription,
    toggleCurrentSearchSubscriptionFlow,
  )

  yield takeLatest(catalogFilterActions.changeFilters, unifiedCurrentSearchFlow, {
    forceUpdate: false,
  })
  yield takeLatest(catalogFilterActions.setInitialFilters, unifiedCurrentSearchFlow, {
    forceUpdate: true,
  })
}
