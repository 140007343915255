import {
  ItemShippingOptionDto,
  ShippingOptionDto,
  PackageSizeShippingOptionDto,
  ItemShippingDetailsDto,
} from 'types/dtos/shipping-option'
import {
  ShippingOptionModel,
  ItemShippingOptionModel,
  PackageSizeShippingOptionModel,
  ItemShippingDetailsModel,
} from 'types/models/shipping-option'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformShippingOptionDto = (
  shippingOption: ShippingOptionDto,
): ShippingOptionModel => ({
  id: shippingOption.id,
  carrierCode: shippingOption.carrier_code,
  carrierIconUrl: shippingOption.carrier_icon_url,
  price: transformCurrencyAmountDto(shippingOption.price),
  packageTypeId: shippingOption.package_type_id,
  discount: shippingOption.discount && {
    percent: shippingOption.discount.percent,
    validFrom: shippingOption.discount.valid_from,
    validTo: shippingOption.discount.valid_to,
  },
  priceBeforeDiscount:
    shippingOption.price_before_discount &&
    transformCurrencyAmountDto(shippingOption.price_before_discount),
  lowestPriceIn30Days:
    shippingOption.lowest_price_in_30_days &&
    transformCurrencyAmountDto(shippingOption.lowest_price_in_30_days),
  restriction: shippingOption.restriction,
  rateUuid: shippingOption.rate_uuid,
  rootRateUuid: shippingOption.root_rate_uuid,
  title: shippingOption.title,
  valueProposition: shippingOption.value_proposition,
  itemOfflineVerificationEnabled: shippingOption.item_offline_verification_enabled,
  deliveryInDays: shippingOption.delivery_in_days,
})

export const transformShippingOptionDtos = (
  shippingOptions: Array<ShippingOptionDto>,
): Array<ShippingOptionModel> => shippingOptions.map(transformShippingOptionDto)

export const transformPackageSizeShippingOptionDto = (
  shippingOption: PackageSizeShippingOptionDto,
): PackageSizeShippingOptionModel => ({
  carrierIconUrl: shippingOption.carrier_icon_url,
  description: shippingOption.description,
  id: shippingOption.id,
  subtitle: shippingOption.subtitle,
  title: shippingOption.title,
})

export const transformPackageSizeShippingOptionDtos = (
  shippingOptions: Array<PackageSizeShippingOptionDto>,
): Array<PackageSizeShippingOptionModel> =>
  shippingOptions.map(transformPackageSizeShippingOptionDto)

export const transformItemShippingOptionDto = (
  shippingOption: ItemShippingOptionDto,
): ItemShippingOptionModel => ({
  carrierCode: shippingOption.carrier_code,
  currency: shippingOption.currency,
  id: shippingOption.id,
  isPickupOnly: shippingOption.pickup_only,
  price: shippingOption.price,
  title: shippingOption.title,
})

export const transformItemShippingOptionDtos = (
  shippingOptions: Array<ItemShippingOptionDto>,
): Array<ItemShippingOptionModel> => shippingOptions.map(transformItemShippingOptionDto)

export const transformItemShippingDetailsDto = (
  shippingDetails: ItemShippingDetailsDto,
): ItemShippingDetailsModel => ({
  isPickupOnly: shippingDetails.pickup_only,
  areMultipleShippingOptionsAvailable: shippingDetails.multiple_shipping_options_available,
  isFreeShipping: shippingDetails.free_shipping,
  price: transformCurrencyAmountDto(shippingDetails.price),
  discount: shippingDetails.discount && {
    maxPercent: shippingDetails.discount.max_percent,
    deliveryType: shippingDetails.discount.delivery_type,
  },
})
