import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AuthenticateUserErrorModel } from 'types/models'

import { State } from './types'
import { AuthExternalRegisterView, AuthView, NewsletterSubscription, stateName } from './constants'

export const initialState: State = {
  isRealNameRequired: false,
  newsletterSubscription: NewsletterSubscription.OptIn,
  isBusinessRegistrationLinkVisible: false,
  authView: AuthView.SelectTypeRegister,
}

const openAuthModal: CaseReducer<
  State,
  PayloadAction<{ authView?: AuthView; isBusiness?: boolean } | undefined>
> = (draft, action) => {
  if (action.payload?.authView) draft.authView = action.payload?.authView

  draft.isAuthModalOpen = true
  draft.isBusiness = action.payload?.isBusiness || false
}

const openTwoFactorLoginView: CaseReducer<
  State,
  PayloadAction<{ authenticateUserError: AuthenticateUserErrorModel; successUrl: string }>
> = (draft, action) => {
  draft.authView = AuthView.TwoFactorLogin
  draft.twoFactorLoginData = {
    ...action.payload.authenticateUserError,
    refUrl: action.payload.successUrl,
  }
}

const getAuthViewToReset = (authView: AuthView | AuthExternalRegisterView) => {
  switch (authView) {
    case AuthView.SelectTypeLogin:
    case AuthView.EmailLogin:
    case AuthView.ResetPassword:
      return AuthView.SelectTypeLogin
    default:
      return AuthView.SelectTypeRegister
  }
}

const closeAuthModal: CaseReducer<State> = draft => {
  draft.isAuthModalOpen = false
  draft.authView = getAuthViewToReset(draft.authView)
}

const setAuthView: CaseReducer<State, PayloadAction<{ authView: AuthView }>> = (draft, action) => {
  const { authView } = action.payload

  draft.authView = authView
}

const setExternalRegisterData: CaseReducer<
  State,
  PayloadAction<{ view: AuthExternalRegisterView; data: State['externalRegisterData'] }>
> = (draft, action) => {
  const { view, data } = action.payload

  draft.authView = view
  draft.externalRegisterData = data
}

const setIsFacebookIsInitialized: CaseReducer<State> = draft => {
  draft.isFacebookIsInitialized = true
}

const setAuthConfigs: CaseReducer<State, PayloadAction<Partial<State>>> = (draft, action) => {
  const { payload } = action

  return { ...draft, ...payload }
}

const authSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    openAuthModal,
    closeAuthModal,
    setAuthView,
    setExternalRegisterData,
    setIsFacebookIsInitialized,
    setAuthConfigs,
    openTwoFactorLoginView,
  },
})

export const { actions } = authSlice
export const plug = { [stateName]: authSlice.reducer }
export default authSlice.reducer
