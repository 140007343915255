import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { all, call, select, take } from 'typed-redux-saga'

import { MEMBER_PROFILE_URL, ROOT_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { EntryType } from 'constants/photo-tip'
import { ItemAlertStatus } from 'constants/item'
import { ItemAfterUploadActions, PromotionStorageKeys } from 'constants/item-upload'
import * as api from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import {
  transformItemEditDto,
  transformBrandDtos,
  transformItemAuthenticityModalDto,
  transformVideoGameRatingDtos,
  transformPhotoTipDtos,
  transformSizeGroupDtos,
  transformPackageSizeShippingOptionDtos,
  transformDynamicAttributes,
  transformItemUploadConfigDtoToModel,
} from 'data/transformers'
import { navigateToPage, scrollToElementById } from 'libs/utils/window'
import { tracker } from 'libs/common/tracker'
import {
  itemUploadStartEventExtra,
  itemUploadSubmitFail,
  itemUploadSubmitSuccess,
} from 'libs/common/event-tracker/events'
import * as mediaUploadSelectors from 'state/media-upload/selectors'
import { actions as mediaUploadActions } from 'state/media-upload/slice'
import { actions as packageSizesActions } from 'state/package-sizes/slice'
import { actions as itemActions } from 'state/items/slice'
import {
  CreateItemResp,
  DraftItemResp,
  ItemCompletionResp,
  ItemResp,
  ResponseError,
} from 'types/api'
import { CurrencyAmountDto } from 'types/dtos'
import { UiState as UiStateEnum } from 'constants/ui'
import { ImageOrientation } from 'constants/images'

import * as statelessActions from './actions'
import * as transformers from './transformers'
import * as selectors from './selectors'
import {
  REQUEST_DELAY,
  WITHOUT_BRAND_ID,
  FieldName,
  IsbnValidity,
  ItemUploadFailReason,
  ItemStatus,
} from './constants'
import { actions } from './slice'
import { setItemIdToStorage, setPromotionsAfterItemUploadToStorage } from './helpers'

export function* fetchCategoryAttributes(catalogId: number | null, itemStatus: ItemStatus) {
  if (!catalogId) return

  yield put(actions.setDynamicAttributesUiState({ uiState: UiStateEnum.Pending }))

  const response = yield* call(api.getCategoryAttributes, catalogId)

  if ('errors' in response) {
    yield put(actions.setCatalogId({ id: null, itemStatus }))
    yield put(actions.fetchCategoryAttributesFailure())

    return
  }

  yield put(
    actions.fetchCategoryAttributesSuccess({
      attributes: transformDynamicAttributes(response.attributes),
      additionalAttributes: response.additional_attributes,
    }),
  )
}

export function* fetchItem({
  payload: { id, tempUuid, userId, setItemStatus },
}: ReturnType<typeof statelessActions.fetchItemRequest>) {
  yield put(statelessActions.fetchCatalogs())
  yield put(statelessActions.getDropOffLocationPrompt({ userId }))

  const configurationResponse = yield* call(api.getItemUploadConfiguration)

  if ('errors' in configurationResponse) return

  const configurationModel = yield* call(transformItemUploadConfigDtoToModel, configurationResponse)
  yield put(actions.setConfiguration({ configuration: configurationModel }))

  if (!id) {
    const sessionResponse = yield* call(api.getSessionDefaults)
    if ('errors' in sessionResponse) return

    yield put(
      actions.setCurrency({ currency: sessionResponse.session_defaults_configuration.currency }),
    )

    yield put(actions.setUiState({ uiState: UiStateEnum.Success }))
    yield call(
      tracker.track,
      itemUploadStartEventExtra({ tempUuid, screenName: Screen.ItemUpload }),
    )

    return
  }

  let additionalHeaders = {}
  if (configurationModel.abTests.multiple_size_groups_item_upload?.variant === 'on') {
    additionalHeaders = { 'X-Enable-Multiple-Size-Groups': 'true' }
  }
  const response = yield* call(api.getItemEdit, { id, additionalHeaders })

  if ('errors' in response) {
    yield put(actions.setUiState({ uiState: UiStateEnum.Failure }))

    return
  }

  const item = transformItemEditDto(response.item)

  yield put(actions.setInitialItemData({ item }))

  const itemStatus = item.isDraft ? ItemStatus.DraftEdit : ItemStatus.Edit
  yield call(setItemStatus, itemStatus)

  yield put(actions.setCurrency({ currency: item.currency }))

  yield call(fetchCategoryAttributes, item.catalogId, itemStatus)

  const selectedValues = transformers.transformDynamicAttributes(item.itemAttributes)
  yield put(actions.setSelectedDynamicAttributeValues({ selectedValues }))

  yield put(statelessActions.fetchPriceSuggestions())
  yield put(statelessActions.fetchItemOfflineVerificationEligibility())
  yield put(actions.setUiState({ uiState: UiStateEnum.Success }))
  yield call(
    tracker.track,
    itemUploadStartEventExtra({
      tempUuid,
      screenName: item.isDraft ? Screen.ItemDraft : Screen.ItemEdit,
    }),
  )
}

export function* convertAssignedPhoto(id: number) {
  const photo = yield* select(mediaUploadSelectors.getPhoto, id)

  return { id, orientation: photo.orientation || ImageOrientation.Degree0 }
}

export function* requestItemAuthenticityModal({
  payload,
}: ReturnType<typeof statelessActions.requestItemAuthenticityModal>) {
  const { catalogId, brandId, id } = yield* select(selectors.selectAttributes)
  const { force, modalDataOnly } = payload

  if (!brandId) return

  const response = yield* call(api.getItemAuthenticityModal, {
    catalogId,
    itemId: id,
    force,
    modalDataOnly,
  })

  if ('errors' in response) return
  if (!response.authenticity_modal) return

  const model = yield* call(transformItemAuthenticityModalDto, response.authenticity_modal)

  yield put(actions.setAuthenticityModalContent({ content: model }))

  if (modalDataOnly) return

  yield put(actions.setIsAuthenticityModalOpen({ isOpen: true }))
}

export function* fetchBrands({ payload }: ReturnType<typeof statelessActions.fetchBrands>) {
  // Prevent request load so takeLatest effect would cancel saga between actual requests
  yield delay(200)

  const { keyword, includeAllBrands } = payload

  const response = yield* call(api.getBrands, { keyword, includeAllBrands })

  if ('errors' in response) return

  const brands = yield* call(transformBrandDtos, response.brands)

  yield put(actions.setBrands({ brands }))
}

export function* selectCatalog({ payload }: ReturnType<typeof statelessActions.selectCatalog>) {
  const currentCatalog = yield* select(selectors.getCurrentCatalog)
  const catalog = yield* select(selectors.getCatalog, payload.catalogId)

  if (!catalog) return

  if (catalog.restrictedToStatusId) {
    yield put(actions.setStatusId({ id: null, itemStatus: payload.itemStatus }))
  }

  if (!catalog.isIsbnInputShown) {
    yield put(actions.setIsbn({ isbn: null, validity: IsbnValidity.Unvalidated }))
  }

  if (!catalog.isBrandSelectShown) {
    yield put(
      actions.setBrand({
        id: WITHOUT_BRAND_ID,
        title: null,
        isLuxury: false,
        itemStatus: payload.itemStatus,
      }),
    )
  }

  if (currentCatalog) {
    if (currentCatalog.sizeGroupId !== catalog.sizeGroupId) {
      yield put(actions.setSizeId({ id: null, itemStatus: payload.itemStatus }))
    }
  }

  yield put(actions.setCatalogId({ id: payload.catalogId, itemStatus: payload.itemStatus }))

  yield call(fetchCategoryAttributes, payload.catalogId, payload.itemStatus)

  const brandIsLuxury = yield* select(selectors.getIsBrandLuxury)

  yield put(actions.setAuthenticityModalContent({ content: null }))

  if (brandIsLuxury) {
    yield put(statelessActions.requestItemAuthenticityModal({ modalDataOnly: true }))
  }
}

export function* fetchSizeGroupsByCatalog() {
  const { catalogId } = yield* select(selectors.selectAttributes)

  if (!catalogId) return

  const isMultipleSizeGroupsEnabled = yield* select(selectors.getIsMultipleSizeGroupsABTestEnabled)
  const response = yield* call(api.getSizeGroupsByCatalog, catalogId, isMultipleSizeGroupsEnabled)

  if ('errors' in response) return

  const transformedDto = yield* call(transformSizeGroupDtos, response.size_groups)

  yield put(actions.setSizeGroups({ sizeGroups: transformedDto.sizeGroups }))
  yield put(actions.setSizes({ sizes: transformedDto.sizes }))
}

export function* fetchVideoGameRatings() {
  const response = yield* call(api.getVideoGameRatings)

  if ('errors' in response) return

  const transformedDto = yield* call(transformVideoGameRatingDtos, response.video_game_ratings)

  yield put(actions.setVideoGameRatings(transformedDto))
}

export function* fetchCatalogs() {
  const response = yield* call(api.getCatalogs)

  if ('errors' in response) return

  const catalogs = yield* call(transformers.transformCatalogs, response.catalogs)

  yield put(actions.setCatalogs({ catalogs }))
}

export function* fetchColors() {
  const response = yield* call(api.requestColors)

  if ('errors' in response) {
    yield delay(REQUEST_DELAY)
    yield put(actions.fetchColorsRequest())

    return
  }

  const colors = yield* call(transformers.transformColors, response.colors)

  yield put(actions.fetchColorsSuccess({ colors }))
}

export function* fetchStatuses() {
  const response = yield* call(api.getStatuses)

  if ('errors' in response) return

  const statuses = yield* call(transformers.transformStatuses, response.statuses)

  yield put(actions.setStatuses({ statuses }))
}

export function* fetchPriceSuggestions() {
  const { catalogId, statusId, brandId } = yield* select(selectors.selectAttributes)

  if (!brandId || !statusId || !catalogId) {
    yield put(actions.clearPriceSuggestions())

    return
  }

  const response = yield* call(api.getItemPriceSuggestions, { brandId, statusId, catalogId })

  if ('errors' in response) {
    yield put(actions.clearPriceSuggestions())

    return
  }

  const suggestions = transformers.transformPriceSuggestionsDto(response)

  yield put(actions.setPriceSuggestions({ suggestions }))
}

export function* toggleSimilarItemsModal() {
  const isSimilarItemsModalOpen = yield* select(selectors.getIsSimilarItemsModalOpen)

  if (isSimilarItemsModalOpen) {
    const { catalogId, statusId, brandId } = yield* select(selectors.selectAttributes)

    if (!brandId || !statusId || !catalogId) {
      yield put(itemActions.resetSimilarItems())

      return
    }

    yield put(itemActions.getSimilarItemsRequest({ brandId, statusId, catalogId }))
  } else {
    yield put(itemActions.resetSimilarItems())
  }
}

export function* fetchBookDetails({ payload }: ReturnType<typeof actions.setIsbn>) {
  if (payload.validity === IsbnValidity.Unvalidated) return

  if (payload.validity === IsbnValidity.Invalid || !payload.isbn) {
    yield put(actions.removeFieldError({ fieldName: FieldName.Isbn }))

    return
  }

  yield put(actions.setIsbnPendingState())
  yield delay(REQUEST_DELAY)

  const response = yield* call(api.getBookDetails, { isbn: payload.isbn })

  if ('errors' in response) {
    yield put(
      actions.setFieldError({
        fieldError: { field: FieldName.Isbn, value: response.message },
      }),
    )

    return
  }

  const bookDetails = transformers.transformBookDetails(response)

  yield put(actions.setBookDetails({ bookDetails }))
}

export function* fetchPackageSizes() {
  const { catalogId, price: priceValue, brandId } = yield* select(selectors.selectAttributes)
  const currency = yield* select(selectors.getCurrency)
  const price: CurrencyAmountDto | undefined = priceValue
    ? { amount: priceValue.toString(), currency_code: currency }
    : undefined

  if (!catalogId) return

  yield put(
    packageSizesActions.fetchCatalogPackageSizesRequest({
      catalogId,
      brandId: brandId || undefined,
      price,
    }),
  )
}

export function* toggleShippmentOptionsModal() {
  const isShipmentOptionsModalOpen = yield* select(selectors.getIsShippingOptionsModalOpen)

  if (!isShipmentOptionsModalOpen) return

  yield put(statelessActions.fetchShippingOptionsRequest())
}

export function* deleteDraft({ payload }: ReturnType<typeof statelessActions.deleteDraft>) {
  const { id } = yield* select(selectors.selectAttributes)

  if (!id) return

  yield put(actions.setIsFormDisabled({ isFormDisabled: true }))
  yield* call(api.deleteItemDraft, id)

  const { userId } = payload
  const redirectUrl = MEMBER_PROFILE_URL(userId)

  yield* call(navigateToPage, redirectUrl)
}

export function* handleNewItemIncompleteTaxAddressResponse({
  meta,
  payload: {
    tempUuid,
    itemStatus,
    screenName,
    additionalHeaders,
    skipPackageSizeValidation,
    trackListingToGoogleTagManager,
    trackListingToBraze,
  },
}: Pick<ReturnType<typeof statelessActions.submitItem>, 'meta' | 'payload'>) {
  yield put(actions.showMissingPostalCode())
  const action = yield* take(actions.hideMissingPostalCode)

  if (!action.payload?.resubmitItem) return

  yield put(
    statelessActions.submitItem({
      additionalHeaders,
      tempUuid,
      itemStatus,
      screenName,
      skipPackageSizeValidation,
      saveAsDraft: meta.saveAsDraft,
      isItemPushedUp: meta.isItemPushedUp,
      trackListingToGoogleTagManager,
      trackListingToBraze,
    }),
  )
}

export function* handleItemSubmitResponseCode({ responseCode, meta, payload }) {
  switch (responseCode) {
    case ResponseCode.IncompleteTaxAddress:
      yield* call(handleNewItemIncompleteTaxAddressResponse, { meta, payload })
      break
    case ResponseCode.PhotoMinimumCountRequired:
      yield put(actions.setIsLuxuryItemModalOpen({ isOpen: true }))
      break
    default:
      break
  }
}

export function* handleReplicaProof(response: ItemResp) {
  yield put(actions.setIsAuthenticityProofModalOpen({ isOpen: true }))

  yield take(actions.setIsAuthenticityProofModalOpen)

  if (
    response.after_upload_actions?.includes(ItemAfterUploadActions.ShowOfflineVerificationModal)
  ) {
    yield put(actions.setIsOfflineVerificationModalOpen({ isOpen: true }))

    return
  }

  yield* call(navigateToPage, MEMBER_PROFILE_URL(response.item.user.id))
}

export function* submitItem({ meta, payload }: ReturnType<typeof statelessActions.submitItem>) {
  const {
    tempUuid,
    itemStatus,
    screenName,
    skipPackageSizeValidation,
    additionalHeaders,
    trackListingToGoogleTagManager,
    trackListingToBraze,
  } = payload

  yield put(actions.setIsFormDisabled({ isFormDisabled: true }))

  let response: DraftItemResp | CreateItemResp | ItemResp | ItemCompletionResp | ResponseError

  const itemAttributes = yield* select(selectors.selectAttributes)
  const assignedPhotos = yield* all(
    itemAttributes.assignedPhotos.map(photoId => call(convertAssignedPhoto, photoId)),
  )
  const dynamicAttributes = yield* select(selectors.getSelectedDynamicAttributes)
  const currency = yield* select(selectors.getCurrency)
  const itemDto = yield* call(
    transformers.transformItemAttributesToItemUploadDto,
    itemAttributes,
    assignedPhotos,
    dynamicAttributes,
    currency,
    tempUuid,
  )

  const { isItemPushedUp, saveAsDraft } = meta

  if (saveAsDraft) {
    if (itemDto.id) {
      response = yield* call(api.updateItemDraft, {
        draftId: itemDto.id,
        draft: itemDto,
        feedbackId: itemAttributes.feedbackId,
        additionalHeaders,
      })
    } else {
      response = yield* call(api.createItemDraft, {
        draft: itemDto,
        feedbackId: itemAttributes.feedbackId,
        additionalHeaders,
      })
    }
  } else if (itemStatus === ItemStatus.DraftEdit && itemDto.id) {
    response = yield* call(api.completeItem, {
      itemId: itemDto.id,
      draft: itemDto,
      feedbackId: itemAttributes.feedbackId,
      skipPackageSizeValidation,
      additionalHeaders,
    })
    if (isItemPushedUp) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowPushedUp)
    } else {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowUploadAnotherItemTip)
    }
  } else if (itemDto.id) {
    response = yield* call(api.updateItem, {
      itemId: itemDto.id,
      item: itemDto,
      feedbackId: itemAttributes.feedbackId,
      skipPackageSizeValidation,
      additionalHeaders,
    })

    if (isItemPushedUp) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowPushedUp)
    }

    if ('item' in response) {
      const { item } = response
      const previousAlert = yield* select(selectors.getPreviousAlertType)

      if (
        previousAlert === ItemAlertStatus.ReplicaProof &&
        item.item_alert?.item_alert_type === ItemAlertStatus.UnderReview
      ) {
        yield call(handleReplicaProof, response)

        return
      }
    }
  } else {
    response = yield* call(api.createItem, {
      item: itemDto,
      feedbackId: itemAttributes.feedbackId,
      skipPackageSizeValidation,
      additionalHeaders,
    })
    if (isItemPushedUp) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowPushedUp)
    } else {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowUploadAnotherItemTip)
    }
  }

  if (!itemAttributes.feedbackId) {
    setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowFeedback)
  }

  if (!response) {
    yield put(actions.setIsFormDisabled({ isFormDisabled: false }))

    return
  }

  if ('errors' in response) {
    yield put(actions.setFieldErrors({ fieldErrors: response.errors }))
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    yield* call(scrollToElementById, response.errors[0]!?.field)
    yield put(actions.setIsFormDisabled({ isFormDisabled: false }))

    tracker.track(
      itemUploadSubmitFail({
        reason: ItemUploadFailReason.ValidationError,
        validationErrors: response.errors.map(error => error.value),
        screen: screenName,
        tempUuid,
      }),
    )

    yield call(handleItemSubmitResponseCode, {
      responseCode: response.code,
      meta,
      payload,
    })

    return
  }

  let redirectUrl = ROOT_URL
  let itemId = itemDto.id

  if ('item' in response) {
    const { item } = response
    const isItemEdit = itemStatus === ItemStatus.Edit
    const isNewListing = !saveAsDraft && !isItemEdit

    if (isNewListing) {
      yield call(trackListingToGoogleTagManager, item)
      yield call(trackListingToBraze, item)
    }

    redirectUrl = MEMBER_PROFILE_URL(item.user.id)
    itemId = item.id
  } else if ('draft' in response) {
    redirectUrl = MEMBER_PROFILE_URL(response.draft.user.id)
    itemId = response.draft.id
  }

  tracker.track(
    itemUploadSubmitSuccess({
      itemId: itemId || undefined,
      tempUuid,
      screen: screenName,
    }),
  )

  if (
    response.after_upload_actions?.includes(ItemAfterUploadActions.ShowOfflineVerificationModal)
  ) {
    yield put(actions.setIsOfflineVerificationModalOpen({ isOpen: true }))

    return
  }

  if (itemId) setItemIdToStorage(itemId)

  yield* call(navigateToPage, redirectUrl)
}

export function* fetchPhotoTips() {
  const response = yield* call(api.getPhotoTips, EntryType.NewItem)

  if ('errors' in response) return

  const photoTipsModel = yield* call(transformPhotoTipDtos, response.photo_tips)

  yield put(actions.setPhotoTips({ photoTips: photoTipsModel }))
}

export function* fetchItemSuggestions() {
  yield delay(REQUEST_DELAY)

  const { title, description, catalogId } = yield* select(selectors.selectAttributes)
  const assignedPhotos = yield* select(selectors.getAssignedPhotos)
  const isMultipleSizeGroupsEnabled = yield* select(selectors.getIsMultipleSizeGroupsABTestEnabled)

  const args = {
    title,
    description,
    catalogId,
    photoIds: assignedPhotos,
    isMultipleSizeGroupsEnabled,
  }

  const response = yield* call(api.getItemSuggestions, args)

  if ('errors' in response) return

  const { colors, sizes, brands, catalogs } = yield* call(
    transformers.transformItemSuggestions,
    response,
  )

  yield put(actions.setSuggestions({ colors, sizes, brands, catalogs }))
}

export function* fetchShippingOptions() {
  const {
    catalogId,
    price: priceValue,
    brandId,
    packageSize,
  } = yield* select(selectors.selectAttributes)
  const currency = yield* select(selectors.getCurrency)
  const price: CurrencyAmountDto | undefined = priceValue
    ? { amount: priceValue.toString(), currency_code: currency }
    : undefined

  if (!packageSize?.id || !catalogId) return

  const response = yield* call(api.getPackageShippingOptions, {
    packageSizeId: packageSize.id,
    catalogId,
    brandId: brandId || undefined,
    price,
  })

  if ('errors' in response) {
    yield put(actions.fetchShippingOptionsFailure())

    return
  }

  const shippingOptions = transformPackageSizeShippingOptionDtos(response.shipping_options)

  yield put(actions.fetchShippingOptionsSuccess({ shippingOptions }))
}

export function* fetchItemOfflineVerificationEligibility() {
  yield delay(REQUEST_DELAY)

  const { catalogId, price, brandId } = yield* select(selectors.selectAttributes)

  if (!brandId || !price || !catalogId) {
    yield put(actions.setIsOfflineVerificationEligible({ eligible: false }))

    return
  }

  const response = yield* call(api.getItemOfflineVerificationEligibility, {
    brandId,
    price,
    catalogId,
  })

  if ('errors' in response) {
    yield put(actions.setIsOfflineVerificationEligible({ eligible: false }))

    return
  }

  yield put(actions.setIsOfflineVerificationEligible({ eligible: response.eligible }))
}

export default function* saga() {
  yield takeLatest(
    [
      actions.setTitle,
      actions.setDescription,
      actions.setCatalogId,
      mediaUploadActions.uploadPhotoSuccess,
      mediaUploadActions.fetchPhotosSuccess,
    ],
    fetchItemSuggestions,
  )

  yield takeLatest(statelessActions.fetchItemRequest, fetchItem)

  yield takeEvery(statelessActions.requestItemAuthenticityModal, requestItemAuthenticityModal)

  yield takeEvery(statelessActions.selectCatalog, selectCatalog)

  yield takeEvery(actions.fetchColorsRequest, fetchColors)
  yield takeEvery(statelessActions.fetchStatuses, fetchStatuses)
  yield takeEvery(statelessActions.fetchSizeGroupsByCatalog, fetchSizeGroupsByCatalog)
  yield takeEvery(statelessActions.fetchVideoGameRatings, fetchVideoGameRatings)
  yield takeLatest(statelessActions.fetchBrands, fetchBrands)
  yield takeLatest(statelessActions.fetchPhotoTips, fetchPhotoTips)

  yield takeEvery(statelessActions.deleteDraft, deleteDraft)
  yield takeEvery(statelessActions.submitItem, submitItem)

  yield takeLatest(
    [
      statelessActions.fetchPriceSuggestions,
      actions.setBrand,
      actions.setStatusId,
      actions.setCatalogId,
    ],
    fetchPriceSuggestions,
  )

  yield takeLatest(actions.toggleIsSimilarItemsModalOpen, toggleSimilarItemsModal)

  yield takeLatest(actions.setIsbn, fetchBookDetails)

  yield takeLatest(
    [actions.setCatalogId, statelessActions.fetchPackageSizesRequest],
    fetchPackageSizes,
  )

  yield takeLatest(actions.toggleIsShippingOptionsModalOpen, toggleShippmentOptionsModal)
  yield takeLatest(statelessActions.fetchShippingOptionsRequest, fetchShippingOptions)

  yield takeLatest(statelessActions.fetchCatalogs, fetchCatalogs)

  yield takeLatest(
    [
      statelessActions.fetchItemOfflineVerificationEligibility,
      actions.setBrand,
      actions.setPrice,
      actions.setCatalogId,
    ],
    fetchItemOfflineVerificationEligibility,
  )
}
