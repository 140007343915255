import { takeEvery, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import * as api from 'data/api'

import { transformRequestReturnDetailsDto } from 'data/transformers/request-return-details'
import { transformReturnShippingOptionDtos } from 'data/transformers/return-shipping-option'
import { transformReturnCurrencyConversionDto } from 'data/transformers/return-currency-conversion'
import { transformReturnShipmentOrderDto } from 'data/transformers/return-shipment-order'

import { actions } from './slice'

export function* getRequestReturnDetailsDetails({
  payload: { transactionId },
}: ReturnType<typeof actions.getReturnShipmentRequest>) {
  const response = yield* call(api.getRequestReturnDetails, transactionId)

  if ('errors' in response) {
    yield put(actions.getReturnShipmentFailure({ error: response?.errors?.[0]?.value || null }))
  } else {
    const returnShipment = transformRequestReturnDetailsDto(response.request_return_details)

    yield put(actions.getReturnShipmentSuccess(returnShipment))
  }
}

export function* getReturnShippingOptions({
  payload: { transactionId },
}: ReturnType<typeof actions.getReturnShippingOptionsRequest>) {
  const response = yield* call(api.getReturnShippingOptions, transactionId)

  if ('errors' in response) {
    yield put(
      actions.getReturnShippingOptionsFailure({ error: response?.errors?.[0]?.value || null }),
    )
  } else {
    const returnShippingOptions = transformReturnShippingOptionDtos(
      response.return_shipping_options,
    )
    const returnCurrencyConversion =
      response.currency_conversion &&
      transformReturnCurrencyConversionDto(response.currency_conversion)

    yield put(
      actions.getReturnShippingOptionsSuccess({
        returnShipment: returnShippingOptions,
        currencyConversion: returnCurrencyConversion,
      }),
    )
  }
}

export function* createCustomReturnShipment({
  payload: { transactionId, returnShippingOption },
}: ReturnType<typeof actions.createCustomReturnShipmentRequest>) {
  const response = yield* call(api.createCustomReturnShipment, {
    transactionId,
    returnShippingOption,
  })

  if ('errors' in response) {
    yield put(
      actions.createCustomReturnShipmentFailure({ error: response?.errors?.[0]?.value || null }),
    )
  } else {
    yield put(actions.createCustomReturnShipmentSuccess())
  }
}

export function* prepareReturnLabelOrder({
  payload: { transactionId, pickupPointCode, packageTypeId, addressId },
}: ReturnType<typeof actions.prepareReturnLabelOrderRequest>) {
  const response = yield* call(api.prepareReturnLabelOrder, {
    transactionId,
    pickupPointCode,
    packageTypeId,
    addressId,
  })

  if ('errors' in response) {
    yield put(
      actions.prepareReturnLabelOrderFailure({ error: response?.errors?.[0]?.value || null }),
    )
  } else {
    const returnShipmentOrder = transformReturnShipmentOrderDto(response.return_label_order)
    const currencyConversion =
      response.return_label_order.currency_conversion &&
      transformReturnCurrencyConversionDto(response.return_label_order.currency_conversion)

    yield put(
      actions.prepareReturnLabelOrderSuccess({
        returnShipmentOrder,
        currencyConversion,
      }),
    )
  }
}

export function* getReturnLabelOrder({
  payload: { orderId },
}: ReturnType<typeof actions.getReturnLabelOrderRequest>) {
  const response = yield* call(api.getReturnLabelOrder, orderId)

  if ('errors' in response) {
    yield put(actions.getReturnLabelOrderFailure())

    return
  }

  const returnShipmentOrder = transformReturnShipmentOrderDto(response.return_label_order)

  yield put(
    actions.getReturnLabelOrderSuccess({
      returnShipmentOrder,
    }),
  )
}

export default function* saga() {
  yield takeEvery(actions.getReturnShipmentRequest, getRequestReturnDetailsDetails)
  yield takeEvery(actions.getReturnShippingOptionsRequest, getReturnShippingOptions)
  yield takeEvery(actions.createCustomReturnShipmentRequest, createCustomReturnShipment)
  yield takeEvery(actions.prepareReturnLabelOrderRequest, prepareReturnLabelOrder)
  yield takeEvery(actions.getReturnLabelOrderRequest, getReturnLabelOrder)
}
