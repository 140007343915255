import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'
import { remove } from 'lodash'
import { arrayMove } from '@dnd-kit/sortable'

import { UiState as UiStateEnum } from 'constants/ui'
import { MediaUploadPhotoModel } from 'types/models'
import { ImageOrientation } from 'constants/images'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  photos: {
    byId: {},
    ids: [],
  },
  uiState: UiStateEnum.Idle,
}

function setUiState(draft: State, uiState: UiStateEnum) {
  draft.uiState = uiState
}

const addPhotos: CaseReducer<State, PayloadAction<{ photos: Array<MediaUploadPhotoModel> }>> = (
  draft,
  action,
) => {
  const { photos } = action.payload

  if (!photos) {
    draft.photos = initialState.photos

    return
  }

  photos.forEach(photo => {
    draft.photos.byId[photo.id] = photo
    draft.photos.ids.push(photo.id)
  })

  setUiState(draft, UiStateEnum.Success)
}

const setPhotoOrientation: CaseReducer<
  State,
  PayloadAction<{ id: number; orientation: ImageOrientation }>
> = (draft, action) => {
  const { id, orientation } = action.payload

  if (!id || draft.photos.ids.length === 0 || !draft.photos.byId[id]) return

  draft.photos.byId[id]!.orientation = orientation
}

const fetchPhotosRequest: CaseReducer<
  State,
  PayloadAction<{
    type: string
    photoEntryId: number | string
    tempUuid: string | null
  }>
> = draft => {
  setUiState(draft, UiStateEnum.Pending)
}

const uploadPhotoFiles: CaseReducer<
  State,
  PayloadAction<{
    files: Array<File>
    type: string
    tempUuid?: string
  }>
> = draft => {
  setUiState(draft, UiStateEnum.Pending)
}

const setUiStateFailure: CaseReducer<State> = draft => {
  setUiState(draft, UiStateEnum.Failure)
}

const removePhoto: CaseReducer<State, PayloadAction<{ id: number }>> = (draft, action) => {
  const { id } = action.payload

  remove(draft.photos.ids, photoId => photoId === id)
}

const changePhotoOrder: CaseReducer<
  State,
  PayloadAction<{ oldIndex: number; newIndex: number }>
> = (draft, action) => {
  const { oldIndex, newIndex } = action.payload

  const reorderedAssignedPhotos = arrayMove(draft.photos.ids, oldIndex, newIndex)

  draft.photos.ids = reorderedAssignedPhotos
}

const mediaUpload = createSlice({
  name: stateName,
  initialState,
  reducers: {
    addPhotos,
    setPhotoOrientation,
    removePhoto,
    changePhotoOrder,
    fetchPhotosRequest,
    fetchPhotosSuccess: addPhotos,
    fetchPhotosFailure: setUiStateFailure,
    uploadPhotoFiles,
    uploadPhotoSuccess: addPhotos,
    uploadPhotoFailure: setUiStateFailure,
  },
})

export const { actions } = mediaUpload
export const plug = { [stateName]: mediaUpload.reducer }
export default mediaUpload.reducer
