import { createSelector } from 'reselect'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

// data selectors

const selectData = (state: AllState) => localState(state).data

export const getFeedbackById = (state: AllState, id: number) => selectData(state).byId[id]!

export const getFeedbackByTranslatedId = (state: AllState, id: number) =>
  selectData(state).byTranslatedId[id]!

export const getFeedbackIds = createSelector(selectData, data => data.ids)

export const getCommentById = createSelector(getFeedbackById, feedback => feedback.comment)

// ui selectors

const selectUI = (state: AllState) => localState(state).ui

const selectFeedbackStateById = (state: AllState, id: number) => selectUI(state).feedbackState[id]!

export const getUiState = createSelector(selectUI, ui => ui.uiState)

export const getCurrentPage = createSelector(selectUI, ui => ui.currentPage)

export const getTotalPages = createSelector(selectUI, ui => ui.totalPages)

export const getEndReached = createSelector(
  [getCurrentPage, getTotalPages],
  (currentPage, totalPages) => !!totalPages && currentPage >= totalPages,
)

export const getFeedbackScreen = createSelector(
  selectFeedbackStateById,
  feedbackState => feedbackState.screen,
)

export const getFeedbackUiState = createSelector(
  selectFeedbackStateById,
  feedbackState => feedbackState.uiState,
)

export const getFeedbackError = createSelector(
  selectFeedbackStateById,
  feedbackState => feedbackState.error,
)

export const getFeedbackIsTranslated = createSelector(
  selectFeedbackStateById,
  feedbackState => feedbackState?.isTranslated,
)

export const getShowFilters = createSelector(selectUI, ui => ui.showFilters)

// combined selectors

export const getTranslatedFeedbackById = createSelector(
  [getFeedbackIsTranslated, getFeedbackById, getFeedbackByTranslatedId],
  (isTranslated, feedback, translatedFeedback) => (isTranslated ? translatedFeedback : feedback),
)
