import { DynamicAttributeDto, DynamicAttributeOptionDto } from 'types/dtos/dynamic-attribute'
import { DynamicAttributeModel, DynamicAttributeOptionModel } from 'types/models/dynamic-attribute'

export const transformDynamicAttributeOptions = (
  dynamicAttributeOptions: Array<DynamicAttributeOptionDto> | undefined,
): Array<DynamicAttributeOptionModel> => {
  if (!dynamicAttributeOptions?.length) return []

  return dynamicAttributeOptions.map(
    ({
      id,
      title,
      type,
      options,
      description,
    }: DynamicAttributeOptionDto): DynamicAttributeOptionModel => ({
      id,
      title,
      type,
      description,
      options: transformDynamicAttributeOptions(options),
    }),
  )
}

export const transformDynamicAttribute = ({
  id,
  title,
  code,
  description,
  placeholder,
  display_type,
  selection_type,
  required,
  selection_limit,
  options,
}: DynamicAttributeDto): DynamicAttributeModel => ({
  id,
  title,
  code,
  description,
  placeholder,
  displayType: display_type,
  selectionType: selection_type,
  required,
  selectionLimit: selection_limit,
  options: transformDynamicAttributeOptions(options),
})

export const transformDynamicAttributes = (
  dynamicAttributes: Array<DynamicAttributeDto>,
): Array<DynamicAttributeModel> => dynamicAttributes.map(transformDynamicAttribute)
