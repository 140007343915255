import { put, takeLatest, fork } from 'redux-saga/effects'
import { call, select, take } from 'typed-redux-saga'

import { getLocalStorageItem, removeLocalStorageItem } from 'libs/utils/localStorage'
import {
  UPLOADED_ITEM_ID,
  UPLOADED_ITEM_PROMOTIONS,
  PromotionStorageKeys,
} from 'constants/item-upload'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { getShowItemUploadFeedback } from 'data/api'

import { actions } from './slice'
import * as statelessActions from './actions'
import * as selectors from './selectors'
import { PromoAfterItemUpload } from './constants'

export function* readLocalStorageIntoState() {
  const itemIdFromStorage = yield* call(getLocalStorageItem, UPLOADED_ITEM_ID)
  const uploadedItemPromotionsFromStorage = yield* call(
    getLocalStorageItem,
    UPLOADED_ITEM_PROMOTIONS,
  )

  yield* call(removeLocalStorageItem, UPLOADED_ITEM_ID)
  yield* call(removeLocalStorageItem, UPLOADED_ITEM_PROMOTIONS)

  const uploadedItemId = parseInt(itemIdFromStorage || '', 10)
  const parsedItemPromotions = JSON.parse(uploadedItemPromotionsFromStorage || '[]')

  if (!uploadedItemId || !parsedItemPromotions.length) return

  yield put(actions.setUploadedItemId({ id: uploadedItemId }))

  if (parsedItemPromotions.includes(PromotionStorageKeys.ShowPushedUp))
    yield put(actions.setShowPushedUp(true))

  if (parsedItemPromotions.includes(PromotionStorageKeys.ShowFeedback))
    yield put(actions.setShowFeedback(true))

  if (parsedItemPromotions.includes(PromotionStorageKeys.ShowUploadAnotherItemTip))
    yield put(actions.setShowUploadAnotherItemTip(true))
}

export function* startPushUpOnUploadForm() {
  const uploadedItemId = yield* select(selectors.getUploadedItemId)
  const showPushedUp = yield* select(selectors.getShowPushedUp)

  if (!uploadedItemId || !showPushedUp) {
    yield put(statelessActions.nextPromoAfterItemUpload())

    return
  }

  yield put(
    actions.setCurrentPromotionAfterItemUpload({
      promo: PromoAfterItemUpload.PushUpCheckout,
    }),
  )
}

export function* startListAnotherPromo() {
  const showUploadAnotherItemTip = yield* select(selectors.getShowUploadAnotherItemTip)

  if (!showUploadAnotherItemTip) {
    yield put(statelessActions.nextPromoAfterItemUpload())

    return
  }

  yield put(
    actions.setCurrentPromotionAfterItemUpload({
      promo: PromoAfterItemUpload.ListAnotherItem,
    }),
  )
}

export function* startItemUploadFeedbackPromo() {
  const isFeedbackEnabled = yield* select(getIsFeatureSwitchEnabled('item_upload_feedback_form'))

  const showFeedback = yield select(selectors.getShowFeedback)

  if (!showFeedback || !isFeedbackEnabled) {
    yield put(statelessActions.nextPromoAfterItemUpload())

    return
  }

  const response = yield* call(getShowItemUploadFeedback)

  if ('errors' in response || !response.show_feedback) {
    yield put(statelessActions.nextPromoAfterItemUpload())

    return
  }

  yield put(
    actions.setCurrentPromotionAfterItemUpload({
      promo: PromoAfterItemUpload.ItemUploadFeedback,
    }),
  )
}

export function* startPromosAfterItemUpload() {
  yield call(readLocalStorageIntoState)

  yield fork(startPushUpOnUploadForm)
  yield take(statelessActions.nextPromoAfterItemUpload)

  yield fork(startListAnotherPromo)
  yield take(statelessActions.nextPromoAfterItemUpload)

  yield fork(startItemUploadFeedbackPromo)
  yield take(statelessActions.nextPromoAfterItemUpload)

  yield put(
    actions.setCurrentPromotionAfterItemUpload({
      promo: PromoAfterItemUpload.Empty,
    }),
  )
}

export default function* saga() {
  yield takeLatest(statelessActions.startPromosAfterItemUpload, startPromosAfterItemUpload)
}
